.brandTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;

    .brandLogoIcon {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 15px;
        margin-right: 10px;
    }

    .brandDefaultLogo {
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        border: 2px solid #FFFFFF;
        background-color: #292D33;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15);
        color: #DFE2EA;
        font-family: 'Silka-Bold', sans-serif;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .brandName {
        width: calc(100% - 40px);
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        white-space: normal;
        .brandNameDataContainer {
            &--segments {
                width: 100%;
            }

            &--competitive {
                width: 80%;
            }
        }
        .noData {
            display: block;
            padding-left: 0;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
        }
        .sampleSize {
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            color: #71767D;
        }
        >span {
            display: block;
            color: #0062FF;
            font-size: 14px;
            line-height: 16px;
        }
        .relative {
            position: relative;
        }
        .statLetter {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 28px;
            height: 28px;
            border: #DFE2EA 1px solid;
            border-radius: 50%;
            font-size: 14px;
            color: #71767D;
            font-weight: 500;
        }
    }
}

.equityCard {
    width: 75%;

    .brandName {
        .brandNameDataContainer {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.formFont {
    color: #000000;
    font-family: "azo-sans-web", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}

.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px;
    letter-spacing: 0;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
    .title {
        color: #000000;
        font-family: "Silka-Semibold";
        font-size: 16px;
    }

    .subtitle {
        color: #71767D;
        font-family: "azo-sans-web";
        font-size: 14px;
    }
}