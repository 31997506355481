.failBackground {
    background: radial-gradient(circle, #FFFFFF 0%, #F0F2F5 100%);
    background-size: 200%;
    height: 100vh;
}

.failContainer {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 80px 0;
    .failHeader {
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: 1px solid #DFE2EA;
        padding-bottom: 20px;
        >img{
            padding: 0 15px;
            &:last-child{
                // border-left: 1px solid #DFE2EA;
            }
        }
    }

    .failContent {
        text-align: center;
        padding: 38px 0;
        border-bottom: 1px solid #DFE2EA;
    }
    
    .failTitle {
        // height: 34px;
        color: #000000;
        font-family: "Silka-Bold", sans-serif;
        font-size: 2rem;
        letter-spacing: -1px;
        line-height: 2.5rem;
        text-align: center;
        padding-bottom: 20px;
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
        width: 100%;
        margin-top: 40px;
        >.blueButton {
            font-size: 16px;
        }
    }

    .description {
        font-family: "azo-sans-web", sans-serif;	
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #71767D;
        word-break: break-word;

    }
}


  
  
