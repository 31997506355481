.brandItemCardBackground {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid #FFFFFF;
    transition: all 0.3s ease-out 0s;
    height: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    cursor: pointer;

    &:hover {
        border: 1px solid #0062FF;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

        .brandClearIcon {
            display: flex;
        }
    }

    .brandTitle {
        flex: 1;
        justify-content: center;
        padding: 0 20px;
        
        .brandLogoIcon {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            overflow: hidden;
            margin-right: 10px;
        }
    
        .brandDefaultLogo {
            height: 50px;
            width: 50px;
            border-radius: 25px;
            font-size: 20px;
            line-height: 24px;
            margin-right: 10px;
        }
    
        .brandName {
            width: calc( 100% - 60px );
            padding-left: 12px;
            color: #000000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 16px;
            line-height: 20px;
        }
    } 
 
    .brandClearIcon {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
    }
}

