.cardContainer.funnelChartContainer {
    .scrollContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 50px;
        padding: 0 0.5rem;
        padding-top: 0;
        position: relative;
        box-sizing: border-box;
        .peopleValue {
            height: 50px;
            margin-top: -50px;
            font-family: "azo-sans-web", sans-serif;
            font-size: 12px;
            font-weight: 700;
            color: #000000;
            line-height: 18px;
            padding-left: 15px;
            >span {
                display: block;
                font-weight: 400;
            }
        }
        .descriptionOverlapContainer {
            // background-color: yellow;
            //pointer-events: none;
            position: absolute;
            width: 110px;
            top: 0;
            bottom: 0;
            left: 2rem;
            display: flex;
            flex-direction: column;

            .itemDescriptionContainer {
                font-family: "azo-sans-web", sans-serif;
                height: 20%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: relative;
                .tooltip {
                    left: 400px;
                    width: 140px;
                    @media screen and (max-width: 812px) {
                        left: 55vw
                    }
                }
                .valueText {
                    color: #000000;
                    // font-family: "azo-sans-web", sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.875rem;
                    text-align: left;
                }

                .categoryText {
                    color: #71767D;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 1.25rem;
                    text-align: left;
                    >span {
                        top: 20px;
                    }
                }

                .percentageText {
                    // color: #71767D;
                    // font-family: "azo-sans-web", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.25rem;
                    text-align: left;
                }

                .statArrow{
                    margin-left: 10px;
                }
                .upDownIcon  {
                    display: inline-block;
                }
            }
        }
    }

}