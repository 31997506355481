.filterCardContainer {
    width: auto;
    border: 1px solid #E2E4E9;
    border-radius: 2px;
    margin-top: 3px;
    

    &.collapse {
        .filterCardHeader .arrowIcon {
            transform: rotate(180deg);
        }
    }
    &.active {
        border: 1px solid #0D51F2;
    }
    .filterCardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F1F2F4;
        padding: 5px 16px;
        height: 30px;
        cursor: pointer;

        &.active {
            background-color: #CFDCFC;
        }

        &.expanded {
            border-bottom: 1px solid #0D51F2 !important;
        }

        &.collapse{
            border-bottom: 1px solid #E2E4E9;
        }

        .categoryTitle {
            color: #2C313A;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            font-weight: 600;
            letter-spacing: 0.16px;
            grid-gap: 5px;
            .match{
                background-color: rgb(255, 247, 0);
            }
            .countContainer{
                text-align: center;
                background-color: #0D51F2;
                width: 20px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500;
                color: #fff;
            }
        }

        .arrowIcon {
            width: 16px;
            height: 16px;
            cursor: pointer;
            transition: all 0.5s;
        }
    }

    .segmentsContainer {
        width: 100%;
        padding-top: 1.875rem;

    }

    .filtersListContainer {
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 10px;
        >div {
            width: 250px;
            padding: 5px 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 12px;
            color: #000000;
            font-weight: 500;
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
            .label {
                width: 100%;
                color: #B5B9BF;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 2px;
                line-height: 18px;
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            .titleContainer{
                color: #2C313A;
                font-size: 10px;
                letter-spacing: 0.02rem;
                line-height: 16px;
                text-transform: uppercase;
                padding: 12px 0px;
                font-weight: 600;
                font-style: italic;
                display: flex;
                .title {  
                    color: #2C313A;
                    .match {
                        background-color: rgb(255, 247, 0);
                        width: fit-content;
                    }
                }
                .warningIcons{
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                    padding: 0px 2px;
                    cursor: pointer;
                }
                .segmentWarning:hover .tooltipWarning {
                    visibility: visible;
                    text-overflow: clip;
                    white-space: normal;
                }

                .segmentWarning .tooltipWarning {
                    visibility: hidden;
                    min-width: 100px;
                    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                    color: #71767D;
                    border-radius: 3px;
                    min-height: 13px;
                    position: absolute;
                    z-index: 1;
                    font-family: "azo-sans-web", sans-serif;
                    width: 80%;
                    padding: 8px 9px;
                    line-height: 1.2;
                    left: 20px;
                    margin-top: -48px;
                    font-size: 10px;
                    box-sizing: border-box;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                    color: #71767D;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0;
                }
            }
        }

    }

    .ageContainer {
        width: 100%;
        padding-top: 1.875rem;

        .generation {
            color: #B5B9BF;
            // font-family: "azo-sans-web", sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.125rem;
        }

        .rangeContainer {
            width: 100%;

            .slider-wrapper {
                display: inline-block;
                width: 20px;
                height: 150px;
                padding: 0;
            }

            .slider-wrapper input {
                width: 150px;
                height: 20px;
                margin: 0;
                transform-origin: 75px 75px;
                transform: rotate(-90deg);
            }

        }
    }
/*
    .geographicContainer {
        width: 100%;
        padding-top: 1.875rem;

        .contentContainer {
            margin-top: 1.25rem;
            display: flex;
            flex-wrap: wrap;

        }

        .label {
            color: #B5B9BF;
            font-family: "azo-sans-web", sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.125rem;
        }

        .dividerLine {
            width: 100%;
            height: 1px;
            background-color: #DFE2EA;
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
        }
    }


    .brandContainer {
        width: 100%;
        padding-top: 1.875rem;

        .label {
            color: #B5B9BF;
            font-family: "azo-sans-web", sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.125rem;
        }

        .dividerLine {
            width: 100%;
            height: 1px;
            background-color: #DFE2EA;
            margin-bottom: 1.875rem;
        }
    }*/
}
.selectedFilterListContainer{
    margin-top: 10px;
    // position: sticky;
    // right: 0;
    // z-index: 4;
    // overflow-x: hidden;
    // overflow-y: auto;
    // width: 0px;
    // height: calc(100vh - 70px);
}
