.cardContainer.equityCompetitiveContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #FFFFFF;
    &.increasedZIndex {
        z-index: 2 !important;
    }
    .scollContainer {
        display: flex;
        width: 100%;
        height: 45rem;
        flex-direction: column;
        margin: 0 0;

        .diagramContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 15rem;
            position: relative;
            border-bottom: 1px solid #F0F3F7;

            .overlapContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                flex-direction: column;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;

                .donutCharContainer {
                    padding-top: 2rem;
                }

                .triangleIcon {
                    width: 1.875rem;
                    height: 0.75rem;
                }
            }

            .additionMove {
                bottom: -0.75rem;
            }
        }

        .bottomContainer{
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .figureContainer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-bottom: 1px solid #F0F3F7;
                &:last-child {
                    border-bottom: none;
                }
                .figureTitle {
                    color: #B5B9BF;
                    letter-spacing: 0.1rem;
                    line-height: 0.875rem;
                    text-align: center;
                    font-family: 'Silka-Bold', sans-serif;
                    font-size: 0.7rem;
                    padding-top: 1.2rem;
                    padding-bottom: 0.625rem;
                    >span {
                        left: calc( 50% - 90px );
                        right: 0px;
                        top: 45px;
                    }
                }

                .figureData {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    padding-bottom: 1rem;

                    .figure {
                        color: #081D58;
                        font-family: 'azo-sans-web', sans-serif;
                        font-size: 1.2rem;
                        font-weight: 700;
                        line-height: 1.625rem;
                        text-align: center;
                        padding-left: 0.25rem;
                        margin-right: 5px;
                    }

                    .upDownIcon {
                        display: flex;
                        align-items: flex-end;
                        >span {
                            left: -100px;
                            top: 20px;
                        }
                    }
                    .icon {
                        width: 0.875rem;
                        height: 0.9rem;
                    }
                }
            }
        }
    }
}
