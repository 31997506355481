.CustomCardContainer {
    width: auto;
    border: 1px solid #E2E4E9;
    border-radius: 2px;
    margin-top: 3px;
    // border: 1px solid #E2E4E9;

    &.collapse {
        .CustomCardHeader .arrowIcon {
            transform: rotate(180deg);
        }
    }
    &.active {
        border: 1px solid #0062FF;
    }
    .CustomCardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F1F2F4;
        padding: 5px 10px;
        height: 30px;
        cursor: pointer;

       &.active {
            background-color: #CFDCFC;
        }

        &.expanded {
            border-bottom: 1px solid #0D51F2 !important;
        }

        &.collapse{
            border-bottom: 1px solid #E2E4E9;
        }
        .LiaBrailleSolidIcon{
            width: 1.5em;
            height: 1.5em;
            margin-top:1px ;
        }
        .categoryCheckBox{
            padding-top: 6px;
            margin-left: 0px;
            margin-right: 6px;
            width: 15px;
            height: 20px;
        }
        input[type=checkbox]:checked {
            accent-color: #0D51F2 !important;
        }
        .categoryTitle {
            color: #2C313A;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            font-weight: 600;
            letter-spacing: 0.16px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            .title{
                &.match {
                    background-color: rgb(255, 247, 0);
                    width: fit-content;
                }
            }
            .categoryTitleInputLimit{
                margin-bottom: 1px;
                pointer-events: none;
            }
            .categoryTitleInput{
                margin-bottom: 1px;
                pointer-events: none;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .countContainer{
                text-align: center;
                background-color: #0D51F2;
                height: 18px;
                width: 18px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500;
                margin: 4px 4px;
                color: #fff;
            }
        }

        .arrowIcon {
            width: 15px;
            height: 16px;
            cursor: pointer;
            transition: all 0.5s;
            margin-left: auto;
        }
    }

    .segmentsContainer {
        width: 100%;
        padding-top: 1.875rem;
        
            .iconsContainer{
                display: flex;
            }
        
            .warningIconsConatiner{
                display: flex;
                justify-content: center;
                align-items: center;
                .WarningIcons {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                    padding: 0px 2px;
                    cursor: pointer;
                }
                .segmentWarning:hover .tooltipWarning {
                    visibility: visible;
                    text-overflow: clip;
                    white-space: normal;
                }
        
                .segmentWarning .tooltipWarning {
                    visibility: hidden;
                    min-width: 100px;
                    border-radius: 6px;
                    min-height: 25px;
                    position: absolute;
                    z-index: 1;
                    font-size: 11px;
                    font-family: "azo-sans-web", sans-serif;
                    width: 90%;
                    padding: 5px 9px;
                    line-height: 1.2;
                    left: 8px;
                }
            }
        
            .showOrHideIconContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .showOrHideIcon {
                    display: none;
                }
            }
        
            &:hover {
                .showOrHideIconContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
        
                    .showOrHideIcon {
                        display: flex;
                        width: 1.625rem;
                        height: 1rem;
                    }
                }
            }
        }
    

    .CustomListContainer {
        width: 100%;
        box-sizing: border-box;
        >div {
            width: 250px;
            padding: 5px 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 12px;
            color: #000000;
            font-weight: 500;
            &:last-child {
                padding-bottom: 1px;
                // border-bottom: none;
            }
            .label {
                width: 100%;
                color: #B5B9BF;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 2px;
                line-height: 18px;
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            .title {
                color: #2C313A;
                font-size: 10px;
                letter-spacing: 0.02rem;
                line-height: 16px;
                text-transform: uppercase;
                padding: 12px 0px;
                font-weight: 600;
                font-style: italic;
            }
            .LiaBrailleSolid{
                margin-top: 3px;
                height: 20px;
                width: 25px;
            }
            .show-color-picker{
                position: relative; 
                white-space: nowrap;
            }
            .show-color-picker-1{
                position: fixed;
                z-index: 9999;
                white-space: nowrap;
            }
            .color-picker{
                position: absolute;
                z-index: 9999;
                input[id*='rc-editable-input-'] + label, svg {
                display: none !important; 
            }
            input[id*='rc-editable-input-'] {
                width: 62px !important;
                height: 30px !important;
                font-size: 11px !important;
                border: 1px solid #fff !important;
                text-align: center !important;
            }
                left: -13px !important;
                top: -194px!important;
            }
        
            
        }

    }

    .ageContainer {
        width: 100%;
        padding-top: 1.875rem;

        .generation {
            color: #B5B9BF;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.125rem;
        }

        .rangeContainer {
            width: 100%;

            .slider-wrapper {
                display: inline-block;
                width: 20px;
                height: 150px;
                padding: 0;
            }

            .slider-wrapper input {
                width: 150px;
                height: 20px;
                margin: 0;
                transform-origin: 75px 75px;
                transform: rotate(-90deg);
            }

        }
    }
}
.CustomSegment{
    display: flex !important;
    flex-direction: row !important;
    font-size: 16px !important;
}

.CustomSegmentTooltip{
    width: auto;
    display: flex;
    .showOrHideIconContainer {
        .showOrHideIcon {
            display: none;
        }
    }
    &:hover {
        .showOrHideIconContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                .showOrHideIcon {
                    display: flex;
                    width: 1.5rem;
                    height: 1rem;
                }
                &.rightRem {
                    right: 1.5rem;
                }
            } 
        }
}

  input[type=type]:focus {
    background-color: white;
    width: 100px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.customTooltip{
    // display: inline;
    &:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
        white-space: nowrap;
      }
      .tooltiptext {
        visibility: hidden;
        min-width: 100px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 6px 9px;
        position: absolute;
        z-index: 1;
        left: 15%;
        font-size: 10px;
        white-space: nowrap;
        margin-bottom: 50px;
        letter-spacing: 0;
        font-weight: 400;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
      }
}

.showOrHideIconContainerEdit:hover .tooltipEdit {
    visibility: visible;
    text-overflow: clip;
    white-space: normal;
}
 .tooltipEdit {
    visibility: hidden;
    min-width: 15px;
    background-color: #edeff5;
    color: #4c4d4e;
    text-align: center;
    border-radius: 6px;
    padding: 6px 9px;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    white-space: nowrap;
    margin-bottom: 50px;
    letter-spacing: 0;
    font-weight: 400;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    margin-top: -43px;
}
.showOrHideIconContainerDelete:hover .tooltipDelete {
    visibility: visible;
    text-overflow: clip;
    white-space: normal;
}
.tooltipDelete {
    visibility: hidden;
    min-width: 15px;
    background-color: #edeff5;
    color: #4c4d4e;
    text-align: center;
    border-radius: 6px;
    padding: 6px 9px;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    white-space: nowrap;
    margin-bottom: 50px;
    letter-spacing: 0;
    font-weight: 400;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    margin-top: -43px;
}
