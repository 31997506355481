@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(27, 163, 121, 0.7);
    }
    
    10% {
        transform: scale(0.95);
        background: rgba(51, 217, 178, 0.5);
		box-shadow: 0 0 0 0 rgba(27, 163, 121, 0.7);
    }
	
	70% {
        transform: scale(1);
        background: transparent;
		box-shadow: 0 0 0 10px rgba(27, 163, 121, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(27, 163, 121, 0);
	}
}

.exportBtnAnimate {
    position: absolute;
    height: 22px;
    width: 22px;
    top: 0px;
    border-radius: 12px;
	box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
	animation: pulse 2s infinite;
}

.exportBtn {
    position: relative;
    height: 22px;
    width: 22px;
    border: 1px solid transparent;
    border-radius: 12px;
    background-color: transparent;
    cursor: pointer;

    .downloadIcon {
        width: 24px;
        height: 24px;
        // margin-top: 2px;
    }

    .progressBarContainer {
        position: absolute;
        width: 22px;
        height: 22px;
        z-index: 10;
        border-radius: 10px;
        top: 0px;
    }
}
