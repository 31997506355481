.inputBox {
    &.disabled {
      background-color: #F3F4F7;
      border: 1px solid transparent !important;;
    }

    img.ddArrow {
      top: 22px ;
      transition: all 0.5s; 
    }

    &:hover {
      cursor: pointer;
    }

    .selectListContainer {
      position: absolute;
      top: 100%;
      left: -2px;
      display: none;
      width: auto;
      min-width: 88px;
      min-height: 23px;
      max-height: 140px;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      border: 1px solid #DFE2EA;
      background-color: #ffffff;
      z-index: 20;
      border-radius: 4px;
      padding: 5px 5px;
      margin: 4px 0;
      white-space: nowrap;

      &.openTop {
        top: unset;
        bottom: 100%;
      }

      .optionGroup{
        padding: 0 .25rem;

        &:not(:first-child) {
          margin-top: 0.5rem;
        }
      }

      hr.divider {
        border: 1px solid #f3f3f3;
        margin: .5rem 0;
      }

      .optionGroupLabel {
        cursor: default;
        padding: 0 1.55rem 0 0.75rem;
        width: auto;
        font-size: 10px;
        margin-bottom: .25rem;
        position: relative;
      }

      .selectItem {
        width: 100%;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        padding: 0.5rem 0.6rem;
        border-radius: 5px;
        text-align: left;
        &.selected {
          color: #0062FF;
          &:hover {
            background-color: #F9FAFB !important;
          }
        }
        &:hover {
          background-color: #F9FAFB !important;
        } 
      }
    }
    
    &.open { 
      border: 1px solid #0062FF;
      padding: 1px;

      img.ddArrow {
        transform: rotate(-180deg);
      }
      .selectListContainer {
        display: block;
      }
      &.disabled {
        border: 1px solid #DFE2EA;
      }
    }
  
    .placeholder {
      padding: 0;
      color: #B5B9BF;
      font-family: "azo-sans-web", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
}
