.filterOptionContainer {
    flex: 1;
    padding: 17px 18px 0px 18px;
    width: 250px;

    @media screen and (max-width: 800px) {
        width: calc(100vw - 40px);
    }

    .hidden {
        display: none;
    }

    .segmentTitleContainer {
        height: 40px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .helpIcon {
        display: flex;

    }

    .helpIcon:hover .tooltipWarning {
        visibility: visible;
        text-overflow: clip;
        white-space: normal;
    }

    .helpIcon .tooltipWarning {
        visibility: hidden;
        min-width: 100px;
        background-color: black;
        color: #FFFFFF;
        border-radius: 3px;
        min-height: 13px;
        position: absolute;
        z-index: 1;
        font-family: "azo-sans-web", sans-serif;
        width: 80%;
        padding: 8px 9px;
        line-height: 1.2;
        left: 20px;
        margin-top: 25px;
        font-size: 10px;
    }

    .segmentHeader {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
    }

    .segmentTitle {
        color: #8A8A8A;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 0px;
    }

    .segmentTitleCategory {
        display: flex;
        color: #8A8A8A;
        font-size: 12px;
        line-height: 16px;
        padding: 15px 0px;
    }

    .categoryCheckBox {
        width: 15px;
        height: 20px;
    }

    .categoryText {
        align-content: center;
        margin-top: -1px;
        white-space: nowrap;
        padding-left: 3px;
    }

    .segmentOptionContainer {
        width: 100%;
        height: 100%;
        position: relative;

        .deleteTitle {
            padding-right: 6px;
            color: #8A8A8A;
            font-size: 15px;
            text-align: center;
            line-height: 19px;
        }

        .inputGroup {
            height: 140px;
            width: auto;
            margin: 25px 0px;

            .inputBox {
                width: auto;
                float: none;
                border: 1px solid #E4E4E4;
                border-radius: 2px;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
                padding: 10px 0px;

                &:focus-within {
                    border: 1px solid #0062FF;
                    box-shadow: none;
                }
            }

            .searchCategoryContainer {
                width: auto;
                padding: 10px 0px;
                display: flex;
            }

            .colorPickerContainer {
                width: auto;
                float: none;
                border: 1px solid #DFE2EA;
                border-radius: 2px;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
                min-height: 40px;
                display: flex;
                align-items: center;

                .inputText {
                    font-size: 12px;
                    color: #000000;
                }

                .colorPicker {
                    top: -210px !important;
                }

                .colorPickerInput {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0px 15px;
                    align-items: center;
                }

                .colorPickerIcon {
                    height: 13px;
                    width: 13px;
                    border-radius: 2px;
                    margin-right: 1rem;
                }

                .colorPickerButton {
                    position: absolute;
                    bottom: 12px;
                    height: 33px;
                    background: rgb(36, 31, 31);
                    border-radius: 5px;
                    z-index: 9;
                    left: 47%;
                    width: 77px;

                    .saveButton {
                        color: #fff;
                    }
                }
            }
        }
    }

    .segmentFilters {
        box-sizing: border-box;
        padding: 20px 20px 0;

        >span {
            display: block;
            width: 100%;
            color: #71767D;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding: 0 5px 10px;
        }
    }

    .modalLayer {
        .segmentFilters {
            justify-content: center;
        }

        .bottomButtons {
            box-shadow: none;

            .create {
                width: 100%;
                height: 50px;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                padding: 15px 0;
                margin: 20px;
            }

            >.button {
                width: auto;
                padding: 15px 40px;
            }
        }
    }

    .expandIcon {
        color: #fff;

        &.active {
            transform: rotate(180deg);
        }
    }

    .filtersGroup {
        background-color: #FFFFFF;

        &.searchResult {
            .filterCardContainer.collapse {
                display: none;
            }
        }

        &.searchResult {
            .CustomCardContainer.collapse {
                display: none;
            }
        }
    }

    .searchContainer {
        width: 100%;
        height: 40px;
        padding: 15px 0px;
        display: flex;
        position: relative;
    }

    .segmentLimitContainer {
        display: flex;
        padding: 10px 0px;
        border: 1px solid coral;
        width: 100%;
        gap: 6px;
        align-items: center;
        height: 20px;


        .segmentsLimitIcon {
            width: 25px !important;
            height: 25px !important;
            margin-left: 10px;
            color: orangered;
        }
    }

    .selectFilterContainer {
        display: flex;
        padding: 10px 10px;
        border: 1px solid #E4E4E4;
        width: 100%;
        gap: 6px;
        align-items: center;
        height: 20px;
        position: static !important;

        .segmentsLimitIcon {
            width: 25px !important;
            height: 25px !important;
            margin-left: 10px;
            color: orangered;
        }

        .searchIconContainer {
            align-items: center;
            display: flex;
        }

        .prefixIcon {
            width: 20px;
            height: 20px;
            color: #8A8A8A;
        }

        .cursor {
            cursor: pointer;
        }

        input {
            border: none;
            font-size: 12px;
            color: #333333;
            font-weight: 400;
            line-height: 20px;
            width: 100%;
        }

        .suggestionContainer {
            border-radius: 0 0 4px 4px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
            border: 1px solid #DFE2EA;
            padding: 0;
            margin: 92px -11px;
            width: 100%;
            max-height: 30vh;
            overflow-y: auto;
            position: absolute;
            top: 4px;
            z-index: 1;

            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 20px;
                cursor: pointer;
                height: 40px;
                color: #71767D;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 7px;

                &:hover {
                    background-color: #F2F4F7;
                }

                .suggestTitle {
                    color: #000000;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 20px;
                    margin-top: 10px;

                }
            }
        }

        .suggestionsList {
            position: absolute;
            top: calc(100% - 12px);
            width: 100%;
            max-height: 200px;
            box-sizing: border-box;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            overflow-y: auto;
            background-color: #ffffff;
            z-index: 1;
            box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
            ;
            transition: width 0.5s ease-in-out;

            >.sItem {
                padding: 5px 15px;
                cursor: pointer;
                color: #2C313A;
                font-size: 12px;
            }
        }

    }
}

.deleteContentContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    width: 250px;
    position: relative;
    padding-top: 10px;

    .deleteSegmentTitleConatiner {
        display: flex;
        padding-top: 10px;

        .deleteTitle {
            padding-right: 6px;
            color: #8e949c;
            font-size: 15px;
            text-align: center;
            line-height: 19px;
        }
    }

    .roundedIcon {
        height: 42px;
        width: 42px;
        border-radius: 20px;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        display: flex;
        background-color: #FEE4E2;
    }
}

.bottomButtons {
    position: sticky;
    min-width: 250px;
    height: 70px;
    padding: 18px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    z-index: 5;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    right: 0;
    // box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);

    .button {
        width: calc(50% - 5px);
        height: 50px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .buttonText {
        width: auto;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        font-family: "Silka-Bold", sans-serif;
        color: #344054;

        &:hover {
            color: #5D5FEF;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .buttonConatiner {
        width: 218px;
        height: 70px;
        padding: 4px 0px;
        background-color: #fff;
        justify-content: center;
        position: absolute;
        top: -80px;
        right: 0;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
        border-radius: 3px;
    }
}