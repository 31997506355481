.pageContainer .contentContainer .rightContainer .pageContent.advancedPage .mainContent .advancedLanding {
    width: 100%;
    max-width: 1520px;
    box-sizing: border-box;
    padding: 40px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    transition: none;
    .tabIndex {
        max-width: 600px;
        min-width: 280px;
        width: calc(50% - 60px);
        min-height: 340px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #FFFFFF;
        border: 1px solid #DFE2EA;
        box-shadow: none;
        margin: 7px 15px;
        padding: 35px 0;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-out 0s;
        &:hover {
            border: 1px solid #0062FF;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15);
        }
        >div {
            margin: 0 auto;
            box-sizing: border-box;
            height: 200px;
            position: relative;
            >.img1, >.img2 {
                display: block;
                position: absolute; 
            }
            &.Differentiation {
                width: 260px;
                max-width: calc( 100% - 40px );
                border-radius: 4px;
                background-color: #EFF1F6;
                >.img1{
                    width: 100%;
                    height: 2px;
                    top: 98px;
                    left: 0;
                    background-color: #FFF;
                }
                >.img2{
                    width: 2px;
                    height: 200px;
                    top: 0px;
                    left: calc( 50% - 1px);
                    background-color: #FFF;
                }
            }
            &.Butterfly {
                width: 6px;
                background-color: #EFF1F6;
                >.img1, >.img2 {
                    width: 75px;
                    height: 30px;
                    border-radius: 2px;
                    background-color: #DFE2EA;
                    &:before, &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        height: 30px;
                        border-radius: 2px;
                        background-color: #DFE2EA;
                    }
                }
                >.img1 {
                    bottom: 35px;
                    right: 12px;
                    &:before {
                        width: 60px;
                        right: 0px;
                        bottom: 35px;
                    }
                    &:after {
                        width: 90px;
                        right: 0px;
                        top: 35px;
                    }
                }
                >.img2 {
                    left: 12px;
                    top: 35px;
                    &:before {
                        width: 90px;
                        left: 0px;
                        bottom: 35px;
                    }
                    &:after {
                        width: 60px;
                        left: 0px;
                        top: 35px;
                    }
                }
            }
            &.Perceptual {
                width: 300px;
                max-width: calc( 100% - 40px );
                overflow: hidden;
                >.img1 {
                   width: 295px;
                   height: 185px;
                   background: url(../../assets/icons/icon_pmap.svg) no-repeat; 
                }
                >.img2 {
                    display: none;
                }
            }
            &.PrePost {
                width: 10px;
                >.img1 {
                    width: 50px;
                    height: 200px;
                    border-radius: 4px 4px 0 0;
                    background-color: #B5B9BF;
                    right: 10px;
                }
                >.img2 {
                    height: 122px;
                    width: 50px;
                    border-radius: 4px 4px 0 0;
                    background-color: #DFE2EA;
                    left: 10px;
                    bottom: 0;
                }
                
            }
        }
        h2 {
            color: #000000;
            font-family: "Silka-SemiBold", sans-serif;
            font-size: 21px;
            line-height: 28px;
            text-align: center;
            padding: 10px 0;
            margin: 20px auto 0; 
        }
        p {
            color: #71767D;
            font-family: 'azo-sans-web', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            padding: 0 40px;
            margin: 0 auto;
        }
        @media screen and (max-width: 812px) {
            width: calc(100% - 40px);
            min-width: calc(100% - 40px);
        }
    }
}
