.filterCommonItemContainer {
    width: fit-content;
    border: 1px solid #E2E4E9;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2C313A;
    line-height: 16px;
    font-weight: 500;
    padding: 3px 10px;
    margin: 2px 0px;
    cursor: pointer;

    &.active {
        background-color: #CFDCFC;
        border: 1px solid #0D51F2 !important;
    }

    &.isExpired{
        background-color: #E2E4E9;
        border: 1px solid #bdbec2;
    }
    
    .segmentOptionTitle {
        font-size: 13px;
        letter-spacing: 0.02rem;
        padding-right: 3px;

        .match {
            background-color: rgb(255, 247, 0);
            width: fit-content;
        }
    }
    .segmenttooltiptext{
           width: 80px;
            padding: 0;
            overflow: hidden;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
    }
    .segmentOption{
        min-width: 20px;
        max-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .filterOptionTitle{
        font-size: 10px;
        letter-spacing: 0.02rem;
        padding-right: 3px;
        .match {
            background-color: rgb(255, 247, 0);
            width: fit-content;
        }
    }
}

.segmentContainer {
    display: flex;
    width: auto;
   
    .segmentsCommonConatiner:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
        white-space: nowrap;
    }
    
    .segmentsCommonConatiner .tooltiptext {
        visibility: hidden;
        min-width: 100px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 6px 9px;
        position: absolute;
        z-index: 1;
        left: 12%;
        font-size: 10px;
        white-space: nowrap;
        margin-bottom: 55px;
        border-radius: 4px;
        letter-spacing: 0;
        font-weight: 400;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    }

    .filterIconsListContainer{
        display: flex;
        .iconsContainer{
            display: flex;
        }

        .warningIconsConatiner{
            display: flex;
            justify-content: center;
            align-items: center;
            .WarningIcons {
                display: flex;
                width: 1.625rem;
                height: 1rem;
                padding: 0px 2px;
                cursor: pointer;
            }
            
            .segmentWarning:hover .tooltipWarning {
                visibility: visible;
                text-overflow: clip;
                white-space: normal;
            }

            .segmentWarning .tooltipWarning {
                visibility: hidden;
                min-width: 100px;
                min-height: 25px;
                position: absolute;
                z-index: 1;
                width: 85%;
                padding: 5px 9px;
                line-height: 1.2;
                left: 20px;
                margin-top: 8px;
                box-sizing: border-box;
                padding: 14px 10px;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                color: #71767D;
                font-size: 11px;
                font-weight: 400;
                letter-spacing: 0;
            }
        }

        .showOrHideIconContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .showOrHideIcon {
                display: none;
            }
        }

        &:hover {
            .showOrHideIconContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                .showOrHideIcon {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                }
            }
        }
    }
}
.showOrHideIconContainerEdit:hover .tooltipEdit {
    visibility: visible;
    text-overflow: clip;
    white-space: normal;
}
 .tooltipEdit {
    visibility: hidden;
    min-width: 15px;
    background-color: #edeff5;
    color: #4c4d4e;
    text-align: center;
    border-radius: 6px;
    padding: 6px 9px;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    white-space: nowrap;
    margin-bottom: 50px;
    letter-spacing: 0;
    font-weight: 400;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    margin-top: -43px;
}
.showOrHideIconContainerDelete:hover .tooltipDelete {
    visibility: visible;
    text-overflow: clip;
    white-space: normal;
}
.tooltipDelete {
    visibility: hidden;
    min-width: 15px;
    background-color: #edeff5;
    color: #4c4d4e;
    text-align: center;
    border-radius: 6px;
    padding: 6px 9px;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    white-space: nowrap;
    margin-bottom: 50px;
    letter-spacing: 0;
    font-weight: 400;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    margin-top: -43px;
}
