.emptyContent {
    width: 80%;
    margin: 60px auto;
    text-align: center;
    .icon {
        width: 40px;
        padding: 20px 27px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #F3F5F9;
        img {
            width: 40px;
        }    
    }
    h3 {
        color: #292D33;	
        font-family: 'Silka-Bold', sans-serif;	
        font-size: 13px;	
        line-height: 22px;	
        letter-spacing: 1px;
        text-align: center;
    }
    p {
        color: #71767D;
        font-family: 'azo-sans-web', sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
    }
}