.pageContainer .contentContainer .rightContainer .pageContent.globalPage {
    .mainContent {
        background-color: rgb(245, 247, 250);
        .dataItemsContainer {
            margin-left: 32px;
            margin-right: 32px;
            margin-bottom: 32px;
            .cardContainer {
                transition: opacity 2s ease-in-out;
                opacity: 1;
            }
        }
    }
}
