    .filterList {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        &.empty {
            display: none !important;
        }
        .clearButton{
            cursor: pointer;
            height: 50px;
        }
        div.filterItem {
            box-sizing: border-box;
            border: 1px solid #DFE2EA;
            border-radius: 29px;
            background-color: #DFE2EA;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            padding: 2px 10px;
            margin: 5px 5px;
            cursor: pointer;
        }
        div.segmentItem {
            background-color: #f6d7d3;
            border: 1px solid #f6d7d3;
        }
        .moreButton{
            width:36px;
            min-width: 36px;
            height: 36px;
            box-sizing: border-box;
            border: 1px solid #DFE2EA;
            border-radius: 18px;
            font-size: 14px;
            line-height: 36px;
            padding: 0;
            color: #000000;
            background-color: #ffffff;
            text-align: center;
            cursor: pointer;

            &:hover {
                border-color: #000000;
            }
        }
        .moreButton:hover + .moreFilters {
            display: flex;
            flex-wrap: wrap;
        }
        .moreFilters {
            display: none;
            position: absolute;
            z-index: 1;
            padding: 10px 20px 20px;
            width: 50%;
            top: 70px;
            left: 20px;
            box-sizing: border-box;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 12px 0 rgba(23,24,26,0.1);
        }
    }
