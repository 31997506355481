.barChartContainer {
    min-width: 900px;
    // min-height: 800px;
    width: 100%;
    padding-left: 2rem;
    // background-color: blue;
    display: flex;
    background-color: #FFFFFF;

    .equityLeftContainer {
        display: flex;
        min-width: 300px;
        width: 300px;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 15px;
        border-right: 1px solid #DFE2EA;
        overflow-y: auto;

        @media only screen and (max-width: 812px) {
            display: none;
        }
    }

    .equityRightContainer {
        min-width: 500px;
        width: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        padding: 30px 18px 10px;
        overflow-x: auto;

        @media only screen and (max-width: 812px) {
            padding-left: 0;
        }

        .barChartContent {
            padding-bottom: 10px;
        }

        .selectContainer {
            display: flex;
            padding-bottom: 30px;
            justify-content: space-between;

            .inputBox {
                width: 200px;
                height: 45px;
                box-sizing: border-box;
                border-radius: 2.5rem;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                padding: 10px 30px;
                margin-right: 20px;
                float: none !important;
                border: 1px solid #dfe2ea;

                img.ddArrow {
                    top: 15px;
                    position: absolute;
                    right: 20px;
                }

                .selectListContainer {
                    max-height: 247px;
                    top: 106%;

                    >.selectItem {
                        font-size: 13px;
                        padding: 3px 30px;
                    }
                }
            }

            .withInformationText {
                min-width: 300px;
                color: #71767d;
                display: flex;
                align-items: center;
                padding-right: 60px;

                >span {
                    display: block;
                    position: relative;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                    text-align: right;
                    z-index: 0;
                    font-size: 13px;
                }
            }
        }

        .equityChartTopContainer {

            display: flex;
            justify-content: space-between;
            padding-right: 2.5rem;
            padding-bottom: 4rem;

            .inputBox {
                width: 210px;
                height: 62px;
                box-sizing: border-box;
                border-radius: 2.5rem;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                padding: 20px 30px;

                img.ddArrow {
                    top: 28px;
                }

                .selectListContainer {
                    max-height: 247px;
                    border-radius: 4px;
                    padding: 10px 0;
                    margin: 4px 0;
                    top: 106%;

                    >.selectItem {
                        font-size: 16px;
                        padding: 4px 30px;
                    }
                }
            }

            .optionTitleContainer {
                display: flex;
                flex-direction: column;
                position: relative;

                .optionContainer {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 15rem;
                    height: 4rem;
                    border-radius: 1.875rem;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

                    &:hover {
                        cursor: pointer;
                    }

                    .optionText {
                        color: #000000;
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 1.375rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                    }

                    .equityUpDownIcon {
                        margin-left: 0.75rem;
                        width: 0.875rem;
                        height: 0.5rem;
                        transition: all 0.5s;
                    }

                    &.open {
                        img.equityUpDownIcon {
                            transform: rotate(-180deg);
                        }
                    }
                }

                .equityValue {
                    // display: none;
                    padding-bottom: 3rem;
                    padding-top: 0.375rem;
                    color: #71767D;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 1.125rem;
                    line-height: 1.25rem;

                }

                .overlapOptionsContainer {
                    position: absolute;
                    top: 3.25rem;
                    left: 0;
                    // right: -3rem;
                    border: 1px solid #DFE2EA;
                    border-radius: 0.25rem;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                    z-index: 10;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    padding-left: 1.25rem;
                    padding-right: 7rem;

                    .option {
                        display: flex;
                        align-items: center;
                        padding-top: 1.25rem;

                        &:hover {
                            color: #0062FF;
                        }
                    }

                    .bottom {
                        padding-bottom: 1.25rem;
                    }
                }
            }

            .sizeContainer {
                display: flex;
                // background-color: yellow;
                align-items: center;

                .sizeIcon {
                    width: 2.125rem;
                    height: 2.125rem;
                    border-radius: 1.0625rem;
                }

                .sizeTitleText {
                    padding-left: 0.5rem;
                    color: #DFE2EA;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: 1.125rem;
                    text-align: right;
                }

                .sizeText {
                    color: #000000;
                    padding-left: 0.25rem;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 1.125rem;
                    font-weight: bold;
                    line-height: 1.125rem;
                    text-align: right;
                }
            }
        }

        .zField {
            position: relative;
            width: 0;
            height: 0;
        }

        .bar:hover {
            opacity: 0.5;
        }
    }
}