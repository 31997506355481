.ButterflyMapContainer {
    width: 100%;
    min-width: 840px;
    height: calc(100% - 80px);
    min-width: 600px;
    min-height: 450px;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .axis {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span {
            display: block;
            height: 12px;
            color: #B5B9BF;
            font-family: 'azo-sans-web', sans-serif;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            text-align: center;
            margin-left: -14px;
        }
        &.xAxis {
            width: 100%;
            height: 50px;
            padding: 0 30px;
        }
    }
    .map {
        width: 100%;
        height: calc(100% - 50px);
        position: relative;
        overflow-y: auto;
        >div {
            width: 50%;
            position: absolute;
            display: flex;
            box-sizing: border-box;
            .tooltip {
                >div {
                    width: calc(100% - 20px);
                    text-align: left;
                    padding: 10px;
                    &.brand {
                        text-align: center;
                    }
                    &.label {
                        padding-bottom: 0;
                    }
                    &.value {
                        padding-top: 0;
                    }
                }
                .statTooltip{
                    text-align: center;
                    font-size: 12px;
                    color: #71767D;
                    font-family: "azo-sans-web", sans-serif;
                    background-color: #DFE2EA;
                    width: 100%;
                    margin: 0 -10px -14px;
                    padding: 10px;
                    span {
                        color: #000;
                        margin: 5px 0;
                    }
                }
            }
            &.better {
                border-left: 1px solid #DFE2EA;
                left: calc(50% - 2px);
                text-align: left;
                .itemTitle {
                    right: 100%;
                    >span {
                        text-align: right;
                    }
                    &--bold {
                        font-weight: bold !important;
                    }
                }
                .itemBar >span {
                    left: calc(100% + 5px);
                }
                .tooltip {
                    top: 30px;
                }
            }
            &.worse {
                border-right: 1px solid #DFE2EA;
                right: calc(50% + 1px);
                justify-content: flex-end;
                text-align: right;
                .itemTitle {
                    left: 100%;
                    text-align: left;

                    &--bold {
                        font-weight: bold !important;
                    }
                }
                .itemBar >span {
                    right: calc(100% + 5px);
                }
                .tooltip {
                    bottom: 50px;
                }
            }

            &.title {
                display: flex;
                align-items: center;
                padding-bottom: 25px;
                >div {
                    color: #081D58;
                    font-family: "Silka-SemiBold", sans-serif;;
                    font-size: 17px;
                    line-height: 24px;
                    text-align: left;
                }

                >.logoIcon {
                    box-sizing: border-box;
                    height: 32px;
                    width: 32px;
                    padding: 2px;
                    border: 1px solid #DFE2EA;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    vertical-align: middle;
                    display: inline-block;
                    margin-right: 16px;
                    >div {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        border-radius: 50%;
                        background-color: #292D33;
                        color: #DFE2EA;
                        font-family: 'Silka-Bold', sans-serif;
                        font-size: 11px;
                        line-height: 14px;
                        text-align: center;
                        padding: 7px 0;
                    }
                }
            }

            &.butterflyItem {
                padding: 0 0 20px;
                cursor: pointer;
                .itemTitle {
                    position: absolute;
                    color: #000000;
                    font-family: 'azo-sans-web';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px;
                    white-space: nowrap;
                    padding: 0 20px;
                }
                .itemBar {
                    height: 30px;
                    position: relative;

                    &--bold {
                        font-weight: bold;
                    }
                    >span {
                        position: absolute;
                        display: block;
                        height: 100%;
                        color: #71767D;
                        font-family: 'azo-sans-web', sans-serif;
                        font-size: 12px;
                        line-height: 30px;
                    }
                }
                &:hover {
                    .itemBar {
                        opacity: 0.6;
                    }
                    .tooltip {
                        display: flex;
                    }
                }
            }
        }

    }
}
