.exportDropdown {
    position: absolute;
    top: 72px;
    right: 10px;
    z-index: 999;
    width: 292px;
    border: 1px solid #DFE2EA;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #DFE2EA;
    background-color: #FFF;
    box-sizing: border-box;

    .fileList {
        padding: 16px;
        background-color: rgba(223,226,234,0.25);
        font-size: 13px;
        border-bottom: 1px solid #DFE2EA;

        .fileListTitle {
            color: #0062FF;
            font-family: 'Silka-Bold', sans-serif;
            line-height: 13px;
            padding-top: 8px;
            padding-bottom: 4px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .fileListString {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0;
            font-family: "azo-sans-web", sans-serif;

            .fileListItem {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                line-height: 16px;
                padding: 0 20px 0 0;

                &_name {
                    color: #000000;
                }

                &_date {
                    color: #71767D;
                }
            }

            .fileListLink {
                color: #0062FF;
                font-size: 13px;
                line-height: 16px;
                cursor: pointer;
                text-decoration: none;
            }

            .fileListLink:hover {
                text-decoration: underline;
            }

            .progressBarContainer {
                width: 22px;
                height: 22px;
            }
        }
    }

    .radioButtonGroup {
        padding: 16px;

        .radioButtonGroupTitle {
            padding: 7px 0;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 13px;
            letter-spacing: 2px;
            line-height: 13px;
            text-transform: uppercase;
        }
    }

    .formatContainer {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        padding-bottom: 0;
        border-top: 1px solid #DFE2EA;

        .formatItem {
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            transition: background-color 0.5s;
            cursor: pointer;

            &:hover {
                background-color: #DFE2EA;
            }

            &--selected {
                border-color: #0062FF;
            }

            &--disabled {
                opacity: 0.5;
                border-color: #DFE2EA;
                &:hover {
                    background-color: initial;
                }
            }

            &--hidden {
                display: none;
            }
        }
    }

    .btnContainer {
        padding: 16px;

        .blueButton.exportModalButton {
            width: 100%;
            height: 50px;
            padding: 15px 0;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 14px;
        }
    }
}

