.headerContainer {
    background-color: #292D33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px 0px 10px 0px;

    .leftPart {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 14px;
        z-index: 11;
        font-family: "Silka-Bold", sans-serif;
        font-size: 17px;

        >div {
            height: 40px;
            line-height: 40px;
            padding: 0 16px;

            >img {
                vertical-align: middle;
            }
        }

        .hpLogo {
            height: 85%;
        }
    }

    .title {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        color: #FFFFFF;
        font-family: 'Silka-Bold', sans-serif;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        padding: 0 180px;
        pointer-events: none;

        @media screen and (max-width: 812px) {
            padding: 0 60px;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    .siteOperation {
        display: flex;
        padding-right: 20px;
        align-items: center;

        .statTesting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            margin-right: 1cm;
            font-family: 'Silka-Bold';
            font-size: 12px;
        }
    }

    @media screen and (max-width: 812px) {
        .leftPart {
            pointer-events: auto;
        }
    }

    .settings {
        display: flex;

        img:hover {
            cursor: pointer;
        }

        .settingsModal {
            position: absolute;
            top: 54px;
            right: 20px;
            z-index: 999;
            width: 200px;
            height: fit-content;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 #DFE2EA;
            background-color: #FFF;
            box-sizing: border-box;

            .header {
                height: 45px;
                border-radius: 4px 4px 0 0;
                background-color: rgba(223, 226, 234, 0.25);
                color: #000000;
                font-weight: 600;
                font-size: 13px;
                display: flex;
                text-align: center;
                align-items: center;
                padding-left: 16px;
            }

            .main {
                height: 100px;
                padding-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 8px;
                padding-right: 16px;
                color: #71767D;
                font-family: "azo-sans-web", sans-serif;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 12px;

                div:hover {
                    cursor: pointer;
                }

                .signOut {
                    color: #E51E00;
                    padding-top: 13px;
                    border-top: #DFE2EA solid 1px;
                }
            }
        }
    }
}
@media only screen and (max-width: 1999px ) and (min-width: 992px) {
     .headerContainer {
         padding: 8px 0px 8px 0px;
     }
 }
 @media only screen and (max-width: 991px) and (min-width: 768px) {
    .headerContainer {
        padding: 5px 0px 5px 0px;
    }
 }
 @media only screen and (min-width: 1200px) {
    .headerContainer {
        padding: 7px 0px 7px 0px;
    }
 }
 
