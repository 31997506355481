.infoModal {
    .modalContent.removeUser {
        max-width: 640px !important;
        height: auto !important;
    }
    .modalContent.infoModalContent {
        height: auto;
        padding: 32px 20px;

        .infoModalTitle {
            margin: 0;
            margin-bottom: 32px;
            color: #000000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 24px;
            letter-spacing: -0.5px;
            line-height: 32px;
        }

        .infoContent {
            padding: 32px 0;
            border-top: 1px solid #DFE2EA;

            .message {
                padding: 0;
                margin: 0;
                color: #71767D;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }

            .message.red {
                color: #E51E00;
            }
        }

        .extraInfoBlock {
            margin-bottom: 32px;
            .extraInfo {
                margin: 0;
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                text-align: center;
                justify-content: center;                
            }
            .extraInfo.title {
                font-weight: 600;
            }
        }

        .buttonGroup {
            display: flex;
            justify-content: center;

            .infoModalButton {
                display: inline-block;
                height: 50px;
                width: auto;
                padding: 0 30px;
                margin: 0;
                margin-left: 16px;
                font-family: 'Silka-Bold', sans-serif;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 50px;
            }
        }
    }
}
