.optionsDropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 64px;
    right: 55px;
    z-index: 999;
    width: 202px;
    border: 1px solid #DFE2EA;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #DFE2EA;
    background-color: #FFF;
    box-sizing: border-box;

    .optionsBlock {
        width: 100%;
        padding: 8px 0;

        .optionItem {
            height: 32px;
            color: #71767D;
            font-family: "azo-sans-web";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 32px;
            padding-left: 15px;

            &:hover {
                background-color: #F2F4F7;
                color: #0062FF;
                cursor: pointer;
            }

            &.separate {
                color: #E51E00;

                &:hover {
                    color: #E51E00;
                }
            }
        }
    }
    
    .divider {
        height: 1px;
        width: 186px;
        background-color: #DFE2EA;
    }
}

