.squareRadioButtonContainer {
    padding: 7px 0;
    &:last-child {
        padding-bottom: 0;
    }

    .squareRadioButton {
        &:checked,
        &:not(:checked) {
            position: absolute;
            left: -9999px;
        }
    
        &:checked + label,
        &:not(:checked) + label {
            position: relative;
            height: 20px;
            padding-left: 32px;
            cursor: pointer;
            display: inline-block;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
        }

        &:disabled + label {
            cursor: not-allowed;
        }
    
        &:checked + label:before,
        &:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            border: 1px solid #B5B9BF;
            border-radius: 2px;
            background: #fff;
        }

        &:disabled + label:before {
            content: '';
            background: #eee;
            cursor: not-allowed;
        }
    
        &:checked + label:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            height: 14px;
            width: 14px;
            border-radius: 1.4px;
            background-color: #17181A;
            transition: all 0.2s ease;
        }
    }
}
