.rangeSliderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.inactive {
        opacity: 0.3;
    }

    .rangeTitle{
        color: #2C313A;
        font-size: 10px;
        letter-spacing: 0.02rem;
        line-height: 16px;
        text-transform: uppercase;
        padding: 12px 0px;
        font-weight: 600;
        font-style: italic;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2C313A;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        line-height: 18px;
        font-style: italic;

        .value {
            color: #000000;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .ranger {
        padding: 30px 10px;

        .knob {
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        }

    }
}