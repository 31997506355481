.leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #DFE2EA;
    overflow: hidden;
    &:hover {
        background-color: #f5f5f5;
    }

    >div {
        width: 100%;
        box-sizing: border-box;
        &.topContainer {
            padding-top: 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            
            .menuContainer {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                color: #71767D;
                margin-bottom: 6vh;
                &.opened {
                    flex-wrap: wrap; 
                }
                .icon {
                    height: 45px;
                    width: 45px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    cursor: pointer;
                    &.active {
                        background: linear-gradient(180deg, #FFFFFF 0%, #F0F3F7 100%);
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
                        border-radius: 30px;
                        cursor: default;
                    }
        
                    .image {
                        height: 21px;
                        width: 21px;
                    }
                }
        
                .menuText {
                    padding-left: 15px;
                    width: 120px;
                    color: #000000;
                    font-family: 'Silka-bold', sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    overflow: hidden;
                    cursor: pointer;
                    &.active {
                        cursor: default;  
                    }
                }

                .tabIcon {
                    width: 14px;
                    margin-left: 15px;
                    cursor: pointer;
                    >img {
                        transition: all .5s;
                        &.ddArrowOpen {
                            transform: rotate(180deg);
                        }
                    }
                }

                &.active {
                    color: #000000;
                }
            }

        }

        &.bottomContainer {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end; 
            padding: 0 0 40px 35px;
            .iconExpand, .iconCollapse {
                height: 50px;
                width: 20px;
                cursor: pointer;
            }

            .copyright {
                display: none;
                width: auto;
                color: #B5B9BF;
                font-family: 'azo-sans-web', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                padding-right: 0.2rem;
            }
            @media screen and (max-width: 812px) {
                display: none;
            }
        }
    }    
}