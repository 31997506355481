.pageContainer .contentContainer .rightContainer .pageContent.segmentsPage {
    .mainContent {
        background-color: rgb(245, 247, 250);
        .horizontalMenuBarContainer {
            @media screen and (max-width: 1280px) {
                &.menuExpand {
                    height: 170px;
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: flex-end;

                }
            }

            .middleHorizontalMenuBarContainer {
                @media screen and (max-width: 1280px) {
                    &.menuExpand {
                        width: calc(100vw - 110px);
                        border-top: 1px solid #DFE2EA;
                        padding: 14.5px 15px 14.5px 0;
                        position: absolute;
                        left: 0;
                        top: 90px;
                        justify-content: flex-end;
                    }
                }
            }
            .rightHorizontalMenuBarContainer {
                @media screen and (max-width: 1280px) {
                    &.menuExpand {
                        height: 90px;
                        justify-content: center;
                        border-left: 1px solid #DFE2EA;
                        padding: 0 1rem;

                    }
                }
            }
        }
        .dataItemsContainer {
            margin-left: 32px;
            margin-right: 32px;
            margin-bottom: 32px;
            .cardContainer {
                transition: opacity 2s ease-in-out;
                opacity: 1;
                .cardHeaderContainer{
                    height: 90px!important;
                    padding: 12px 1.5rem;
                }
                &.segmentHidden {
                    display: none;
                }
            }
            .createCustomContainer {
                height: 100%;
                min-height: calc( 100vh - 230px );
                width: 33.33%;
                min-width: 260px;
                max-width: 450px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 50px;

                .contentContainer {
                    max-width: 250px;
                    // min-width: 250px;
                    display: flex;
                    flex-direction: column;
                    color: #71767D;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    font-family: "azo-sans-web", sans-serif;
                    justify-content: center;
                    align-items: center;
                    >div {
                        width: 250px;
                    }
                    .iconContainer {
                        cursor: pointer;
                        height: 60px;
                        width: 60px;
                        border-radius: 35px;
                        background-color: #FFFFFF;
                        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 27px;
                        transition: all .3s ease-in-out;
                        &:hover {
                            box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
                        }
                    }
                }
            }
        }
    }
}
