.statusMessage {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: -100px;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1BA379;
    transition: all 1s;

    &--opened {
        bottom: 0;
    }

    &.alternativeBackgroundColor {
        background-color: #0062FF !important;
    }

    .textContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 140px;
        color: #FFFFFF;
        font-family: "azo-sans-web", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        .logoContainer {
            margin-right: 16px;
        }

        .statusText {
            font-weight: 700;
        }
    }

    .closeButton {
        margin-right: 30px;
        cursor: pointer;
    }
}
.statusMessageExists {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: -100px;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:  #ff6863;
    transition: all 1s;

    &--opened {
        bottom: 0;
    }
    &.alternativeBackgroundColor {
        background-color: #0062FF !important;
    }
    .textContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 140px;
        color: #FFFFFF;
        font-family: "azo-sans-web", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        .logoContainer {
            margin-right: 16px;
        }

        .statusText {
            font-weight: 700;
        }
    }

    .closeButton {
        margin-right: 30px;
        cursor: pointer;
    }
}
