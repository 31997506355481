.react-responsive-modal-container {
    display: flex;
}

.warning-modal.react-responsive-modal-modal {
    overflow-y: revert !important;
    width: 400px;
    max-height: 800px;
    border: none !important;
    min-height: 50px;
    outline: #FFFFFF !important;
    border-radius: 4px;
    box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
    transition: 0.5s ease-in-out !important;
    flex-direction: column;
    max-height: 90vh;
    display: flex;
    margin: auto;
}

.views-validation-filter-container {
    margin: 5px 0px 30px 0px;
}

.views-modal.react-responsive-modal-modal {
    min-width: 400px !important;
    padding: 1.2rem !important;
    margin: 0;
    position: absolute;
    right: 0;
    min-height: calc(100vh - 30px);
}

.delete-modal.react-responsive-modal-modal {
    width: 450px;

    .modalHeaderContainer {
        gap: 20px !important;
    }

    .warningIcon {
        color: rgb(0, 0, 0);
    }

    .modalTitle {
        line-height: 30px !important;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70px;
    }

    .checkboxcontainer {
        display: flex;
        gap: 10px;
        height: 40px;
        align-items: center;
    }

    .deleteContentContatiner {
        width: 80%;
        display: flex;
        align-items: center;
        padding: 0px 75px;
    }
}

.warning-modal.react-responsive-modal-modal {
    .react-responsive-modal-closeButton {
        top: 35px;
        color: #555658;
        font-size: 16px;
    }

    .modalHeader {
        height: 70px;
        color: black;
        font-size: 12px;
        display: flex;
        border-bottom: none;
        align-items: center;
        gap: 10px;
        font-family: "Inter", sans-serif;
    }

    .modalHeaderContainer {
        height: 40px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    .modalHeaderContent {
        align-items: center;
    }

    .roundedLogo {
        height: 45px;
        width: 45px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sharedSegmentsLogo {
        background-color: #f1e6bf;
    }

    .viewModalLogo {
        background-color: #F4EBFF;
    }

    .viewModalroundedLogo {
        background-color: #F4EBFF;
        border: 4px solid #f9f5ff;
        height: 32px;
        width: 32px;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        .screenIcon {
            width: 16px;
            height: 16px;
        }
    }

    .viewListContentContainer {
        height: 60px;
        width: 230px;
        font-family: "Inter", sans-serif;
        width: 230px;

        .header {
            font-weight: 600;
            height: 22px;
            font-size: 13px;
            color: #53389e;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .subHeader {
            font-size: 10px;
            color: #6941c6;
            line-height: 23px;
        }

        .content {
            font-size: 11px;
            color: #8b73c2;
            line-height: 20px;
        }
    }

    .viewCheckBox {
        width: 30px !important;
        height: 30px !important;
        border-radius: 25px !important;
    }

    .viewModalDeleteLogo {
        background-color: #fee4e2;
        border: 8px solid #fef3f2;
    }

    .selectListConatiner {
        max-height: 150px;
        min-height: 40px;
        overflow-y: auto;
        position: relative;
    }

    .warningIcon {
        font-size: 25px;
        color: rgb(241 163 7);
        font-weight: 600,
    }

    .closeIcon {
        color: #98a2b3;
        right: 19px;
        position: absolute;
        top: 31px;
        font-size: 26px;
        cursor: pointer;
    }

    .segmentsWarningIcon {
        padding-left: 5px;
        font-size: 20px;
        color: rgb(222 146 65);
        position: relative;
        display: flex;
        cursor: pointer;
    }

    .informationIcon {
        font-size: 25px;
        color: rgb(191 171 52);
        padding: 0px 5px 0px 5px;
    }

    .modalContent {
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: black;
        min-height: 40px;
        flex: 1;
        bottom: 0px;
        overflow-y: auto;
        max-height: calc(100% - 100px);

        .inputBox.dateInput {
            .react-date-picker__calendar {
                z-index: 2;
            }

            .react-date-picker__inputGroup {
                display: flex;
                align-items: center;
            }

            .react-date-picker__button {
                padding-right: 28px;
            }

            .react-date-picker.dateInput_picker input,
            .react-date-picker__inputGroup__divider {
                font-weight: 500;
                font-size: 12px;
            }
        }

        .react-calendar {
            // min-width: 390px;
            top: 3px;
            position: relative;
        }
    }

    .form-container-modalContent {
        padding: 0.8rem 0.3rem 0.3rem 0.3rem;
        gap: 0.8rem;
    }

    .viewListConatiner {
        display: flex;
        gap: 4px;
        padding: 16px;
        border: 1px solid #e4e7ec;
        height: 68px;
        border-radius: 3px;
        margin-bottom: 10px;
        cursor: pointer;

        &.active {
            border: 1px solid #7656d9;
            background-color: #f9f5ff;
        }
    }

    .viewsSelectListContainer {
        max-height: 350px;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .viewsActionsListConatiner {
        max-height: calc(100vh - 62vh);
    }

    .viewsDataList {
        border-bottom: 3px solid #f3f3f3;
    }

    .viewsBox {
        width: 100%;
        display: flex;
    }

    .viewCheckBoxContainer {
        margin: 5px 0px;

        input[type=checkbox] {
            width: 18px;
            height: 18px;
            cursor: pointer;
            border-radius: 8% !important;
            background-color: #fff;
            position: relative;
            transition: border 0.2s ease, background-color 0.2s ease;
            // clip-path: circle(50%);

            &:disabled {
                background-color: #ccc;
            }
        }

        input[type=checkbox]:not(:checked) {
            // appearance: none;
            // clip-path: circle(50%) !important;
            border: 1.65px solid #D0D5DD;
        }

        input[type=checkbox]:checked {
            height: 18px !important;
            width: 18px !important;
            gap: 10px;
            accent-color: #fff;
            cursor: pointer;
            border: 2px solid #e4e7ec;
            border-radius: 100% !important;
            // clip-path: circle(45% at 50% 50%) !important;
            box-shadow: 1px 2px 0px #1018280D;

            &:disabled {
                background-color: #ccc;
            }
        }

        input[type=checkbox]:checked::before {
            appearance: none !important;
        }

        input[type=checkbox]:checked {
            accent-color: #7556d9;
            border: 2px solid #7556d9;
        }

        .viewCheckBox {
            width: 16px;
            height: 10px;
        }
    }

    .viewsContentBoxConatiner {
        display: flex;
        gap: 16px;
        align-items: center;
        width: 95%;
    }

    .view-input {
        border: 1px solid #D0D5DD !important;
        padding: 0.9rem;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 3px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        letter-spacing: 0.25px;
    }

    .suggestionContainer {
        border-radius: 0 0 4px 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
        border: 1px solid #DFE2EA;
        padding: 0;
        margin: 43px 0px;
        width: 100%;
        max-height: 13vh;
        overflow-y: auto;
        position: absolute;
        top: 4px;
        z-index: 1;

        li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 20px;
            cursor: pointer;
            height: 40px;
            color: #71767D;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: 7px;

            &:hover {
                background-color: #F2F4F7;
            }

            .active {
                color: blueviolet !important;
            }

            .suggestTitle {
                color: #000000;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 10px;

            }
        }
    }

    .iconConatiner {
        width: 14.67px;
        height: 14.67px;
    }

    .noViewsContainer {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .view-input-wrapper,
    .noViewsContainer {
        max-width: 395px;

        .content {
            margin-top: -8px;
            color: #475467;
            font-family: Inter;
            font-size: 11px;
            font-weight: 400;
            line-height: var(--LabelSmallLineHeight);
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

        }

        .css-19bb58m,
        .css-1tz5dpy-control {
            cursor: pointer;
        }

        .css-1gik8zl-control{
            border-color: rgb(217, 217, 217);
        }
    }

    .customInputContainer {
        position: relative;
        min-width: 368px;

        .customInputDate {
            display: flex;
        }

        .customDateInputContainer {
            width: 175.5px;
            margin: 0px;
            height: 45px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(217, 217, 217);
        }

        .toContainer {
            border: none;
            width: 40px;
            min-width: 20px;
            display: flex;
            justify-content: center
        }
    }

    .dropdown {
        .css-14z137k-singleValue {
            margin-left: 28px;
        }
    }

    .view-input-warpper-input {
        height: 43px;
    }

    .viewSelectInput {
        border: 1px solid #D0D5DD;
        max-height: 47px;
        font-size: 14px;
    }

    .css-1jqq78o-placeholder {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #667085 !important;
        margin-left: 28px;
    }

    .view-modal {
        .css-1jqq78o-placeholder {
            font-size: 12px;
            letter-spacing: 0.5px;
            color: #667085 !important;
            margin-left: 0px !important;
        }
    }

    .view-label {
        color: #344054;
        font-size: 14px;
        letter-spacing: -0.25px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
    }

    .deleteContentContainer {
        font-size: 13px;
        width: 100%;

        .delete-sublabel {
            color: #475467;
            font-weight: 400;
        }
    }

    .titleConatiner {
        color: #333333;
        font-size: 15px;
        font-weight: 450;
        padding: 0px 6px;
        font-family: "azo-sans-web", sans-serif;
    }

    .modalTitle {
        font-size: 16px;
        color: #101828;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 18px;
        font-family: 'Inter', sans-serif;
        padding-bottom: 0.15rem;
    }

    .modalSubTitle {
        color: #475467;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        font-family: 'Inter', sans-serif;
        padding-top: 0.05rem;
    }

    .modalContentText {
        color: #66686b;
        font-size: 13px;
        font-weight: 450;
        padding: 15px 0px;
        font-family: "azo-sans-web", sans-serif;
    }

    .expiredFiltersContainer {
        min-height: 80px;
    }

    .expiredItem {
        font-size: 13px;
        color: #000000;
        font-weight: 500;
        padding: 5px 30px;
    }



    .buttonContainer {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #0062FF;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0;
        text-align: center;
        cursor: pointer;
        border: none;
        transition: all 0.3s ease-out 0s;

        &:hover {
            background-color: #0062FF;
            box-shadow: 0 2px 12px 0 rgba(0, 98, 255, 0.5);
        }

        &.disabled {
            opacity: 0.5;
            cursor: default;
            background-color: #1F68DC;
            pointer-events: none;
            border: 1px solid #0062FF;
        }
    }

    input[type=checkbox] {
        height: 18px !important;
        width: 18px !important;
        clip-path: none !important;
        gap: 10px;
        accent-color: #7556d9;
        cursor: pointer;
        border: 2px solid #d0d5dd;
        margin-left: 1px;

        &:disabled {
            background-color: #ccc;
        }
    }

    input[type=checkbox]:not(:checked) {
        appearance: none;
        border: 2px solid #d0d5dd;
        clip-path: none !important;
        width: 16px !important;
        height: 16px !important;
    }

    input[type=checkbox]:checked {
        border: 2px solid #7556d9;
    }

    .checkbox {
        border-radius: 3px;
        // -webkit-appearance: none !important;
        // -moz-appearance: none;
        // appearance: none;
    }

    .css-tj5bde-Svg {
        color: #667085;
    }

    .modalListContainer {
        padding-left: 25px;
    }

    .warningText {
        font-size: 16px;
        padding: 0px 10px 10px 10px;
        font-weight: 600;
    }

    .selectListItem {
        display: flex;
        text-align: center;
        align-items: center;
        height: 30px;

        &.active {
            &:hover {
                background-color: #f2f4f7;
                cursor: pointer;
            }
        }
    }


    .checkBoxTextConatiner {
        margin-left: 5px;

        &.disabled {
            color: #a9a3a3;
        }
    }

    .warningConatiner {
        display: flex;
        text-align: center;
        align-items: center;
        min-height: 20px;
        border: 1px solid #DFE2EA;
        margin: 5px 0px 10px 0px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .segmentWarning {
        color: #000000;
    }

    .segmentWarning:hover .tooltipWarning {
        visibility: visible;
        text-overflow: clip;
        white-space: normal;
        color: #000000;
    }

    .segmentWarning .tooltipWarning {
        visibility: hidden;
        min-width: 100px;
        border-radius: 6px;
        min-height: 25px;
        position: absolute;
        z-index: 1;
        font-size: 11px;
        font-family: "azo-sans-web", sans-serif;
        padding: 5px 9px;
        line-height: 1.5;
        background-color: #f2f4f7;
        color: #000000 !important;
    }
}