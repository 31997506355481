
.brandItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 13px 16px;
    position: relative;
    
    .brandItemRightContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        padding-left: 10px;
        cursor: pointer;
        top:17px;
        .showOrHideIconContainer {
            .showOrHideIcon {
                display: none;
            }
        }
    
        &.noHidden {
            cursor: default;
            &:hover {
                opacity: 1;
                .showOrHideIcon {
                    display: none!important;
                }
            }
        }
    
        &.brandHidden {
            opacity: 0.5;
            .showOrHideIconContainer {
                position: absolute;
                right: 0;
                width: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                top:17px;
                .showOrHideIcon {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                }
    
                &.rightRem {
                    right: 1.5rem;
                }
            }
        }

        &:hover:not(:has(.segmentWarning:hover)) {
            .showOrHideIconContainer {
                opacity: 0.5;
                position: absolute;
                right: 0;
                width: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                top:17px;
                .showOrHideIcon {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                }
    
                &.rightRem {
                    right: 1.5rem;
                }
            }
        }
    
        .brandTitle {
            width: 100%;
            .brandLogoIcon, .brandDefaultLogo {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 1.5625rem;
                object-fit: cover;
                border: 2px solid #FFFFFF;
                border-radius: 1.6875rem;
                box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15);
                margin-right: 0.75rem;
            }
    
            .brandName {
                width: calc( 100% - 3.875rem);
                color: #000000;
                font-family: "Silka-SemiBold", sans-serif;
                font-size: 13px;
                line-height: 18px;
                white-space: normal;
            }
    
        }
    }
    
    .legend {
        height: 8px;
        width: 8px;
        border-radius: 4px;
        &:hover {
            cursor: pointer;   
        }
    }
    .color {
        height: 8px;
        width: 8px;
        border-radius: 4px;
    }
    .operateContainer {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 15px;
        >div {
            cursor: pointer;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            margin: 5px;
        }
    }    
}
    .show-color-picker{
        position: relative; 
        white-space: nowrap;
    }
    .show-color-picker-1{
        position: fixed;
        z-index: 9999;
        white-space: nowrap;
    }
    .color-picker{
        position: absolute;
        z-index: 9999;
        input[id*='rc-editable-input-'] + label, svg {
        display: none !important; 
    }
    input[id*='rc-editable-input-'] {
        width: 62px !important;
        height: 30px !important;
        font-size: 11px !important;
        border: 1px solid #fff !important;
        text-align: center !important;
    }
        left: 3px !important;
        top: -174px!important;
    }

    div.arrow-right {
        width: 180px !important;
        background: rgb(255, 255, 255) !important;
        border-radius: 2px !important;
        box-shadow: 0px 0px 18px 5px rgba(0,0,0,0.15)!important;
        box-sizing: initial !important;
        font-family: Menlo !important;
    }
    
    div.arrow-right:after {
    content:'';
        position: absolute;
        top: 100%;
        left: 5px;
        width: 0; 
        height: 0; 
        width: 0; 
        height: 0; 
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 10px solid #FFFFFF;
    }
    
    