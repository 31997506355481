.brandItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    // border-radius: 4px;
    padding: 12px 40px;
    cursor: pointer;
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    &:hover {
        background-color: #F2F4F7;
    }
}