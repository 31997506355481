// .react-responsive-modal-root {
//     top: 20px !important;
//     left: auto !important;
//     bottom: auto !important;
// }
.add-filter-modal.react-responsive-modal-modal {
    max-width: 600px !important;
    min-width: 600px !important;
    height: 100vh;
    margin: 0;
    position: absolute;
    right: 0;
    input[type=checkbox] {
        height: 15px !important;
        width: 10px !important;
    }
    input[type=checkbox]:not(:checked){
        appearance: auto;
    }
}
.edit-filter-modal.react-responsive-modal-modal {
    overflow-y: revert !important;
}
.modalHeader{
    height: 70px;
    color: black;
    font-size: 12px;
    font-family: "azo-sans-web", sans-serif;
    border-bottom: 0.5px solid #f6f3f3;
    display: flex;
    .rounded{
        height: 40px;
        width: 40px;
        border-radius: 10px;
        border: 0.5px solid #B5B9BF;
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
    }
    .roundedIcon{
        height: 42px;
        width: 42px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
        background-color: #cc9c96 ;
        opacity: 0.5;
    }
    .headingText{
        font-size: 14px;
        padding-left: 10px;
        color: #71767D;
    }
    span{
        font-weight: 401;
        font-size: 16px;
        padding-right: 5px;
        color: black;
    }
    
}
.container{
    border-bottom: 0.5px solid #f6f3f3;
    padding-bottom: 20px;

input{
    border: none;
}
.inputLabel{
    font-weight: 500 !important;
    padding-top: 10px;
    font-size: 12px;
    letter-spacing: 0.05rem;
}
.inputBlock{
    padding-top: 5px;
    .multiselect {
        width: 200px;
      }
      
      .selectBox {
        position: relative;
      }
      
      .selectBox select {
        width: 100%;
        font-weight: bold;
      }
      
      .overSelect {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      
      #checkboxes {
        display: none;
        border: 1px #dadada solid;
      }
      
      #checkboxes label {
        display: block;
        font-family: "azo-sans-web", sans-serif;
        color: #71767D;
        font-size: 13px;
        padding: 4px 0px 4px 0px ;
      }
      
      #checkboxes label:hover {
        background-color: #1e90ff;
      }
}
.search-input{
    margin-top: 4px;
    min-width: 290px;
}
.inputBox{
    border: 1px solid #dfe2ea;
    // float: left;
    margin-top: 4px;
    float: left;
    min-width: 297px;
    height: 40px;
    border-radius: 4px;
    float: none !important;
}

.inputBox .placeholder{
    color: #71767D;
    font-size: 14px;
}
}
.filterContainer{
    border-bottom: 0.5px solid #f6f3f3;
    min-height: 210px;
    padding: 0px 10px 10px 0px;
    .filterCard{
        min-height: 35px;
        border: 1px solid #dfe2ea;
        margin-top: 20px;
        display: inline-block;
        align-items: center;
        padding: 10px 0px 0px 0px;
        min-width: 590px;
        border-radius: 3px;
        .categoryData {
            display: flex;
            align-items: center;
            padding: 0px 0px 10px 10px;
            width: auto;
            .ddArrow{
                margin-top: 7px;
            }
            .filter-checkbox {
                margin-left: auto;
            }
        }
        .categoryName{
            padding-left: 10px;
            font-size: 15px;
            font-weight: 500;
        }
        .selectedFilters{
            // padding-left: 10px;
            padding: 5px 10px 0px 10px;
            font-size: 12px;
        }
        .filterLabelCard{
            color: black;
            font-size: 10px;
            font-family: "azo-sans-web", sans-serif;
            border-bottom: 0.5px solid #f6f3f3;
            display: flex;
            color: #4133b5;
            .rounded{
                justify-content: center;
                align-items: center;
                text-align: center;
                display: flex;
            }
            .headingLabel {
                flex: 1;
            }
            .headingText{
                font-size: 14px;
                padding-left: 10px;
                color: #4133b5;
            }
            span{
                font-size: 10px;
                padding-right: 5px;
                color: #4133b5;
            }
        }
    }
}
.datesContainerRoot{
    border-bottom: 0.5px solid #f6f3f3;
    padding-bottom: 10px;

.datesContainer{
    display: flex;
    justify-content: space-between;
    .react-date-picker__wrapper{
        width: 270px;
        border: 0.5px solid #dfe2ea;
        height: 40px;
        border-radius: 3px;
    }
    .inputBox{
        padding-left: 10px;
        font-size: 12px;
        font-weight: 200;
        float: none !important;
        border: 1px solid #dfe2ea;
        // float: left;
        margin-top: 4px;
        float: left;
        min-width: 297px;
        height: 40px;
        border-radius: 4px;
        float: none !important;
        margin-top: 10px;
    }
    .inputLabel{
        font-weight: 500 !important;
        padding-top: 10px;
        font-size: 12px;
        letter-spacing: 0.05rem;
    }
}

}

.headingText span,.datesContainerRoot span{
    font-size: 13px;
    letter-spacing: 0.02rem;
    padding-right: 3px ;
}
.buttons{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}

.removeButton{
    margin-bottom: 24px;
    height: 50px;
    width: 100px;
    border: 1px solid #dfe2ea;
    border-radius: 4px;
    background-color: #d9402c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
    &--disabled {
        opacity: 0.5;
    }
}

.cancelButton{
    box-sizing: border-box;
    margin-bottom: 24px;
    height: 50px;
    width: 188px;
    border: 1px solid #dfe2ea;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #71767D;
}
.delModalCancelButton{
    box-sizing: border-box;
    margin-bottom: 24px;
    height: 50px;
    width: 100px;
    border: 1px solid #dfe2ea;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #71767D;
}

input[type="checkbox"] {
    width: 15px;                         /* Set width */
    height: 10px;                        /* Set height */
    clip-path: circle(56% at 50% 50%);   /* Set the clip path of circle*/
  }
  

