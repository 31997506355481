.rightTitle {
    position: absolute;
    top: 15px;
    right: 40px;
    text-align: right;
    color: #000000;
    font-family: "Silka-Bold", sans-serif;
    font-size: 24px;
    line-height: 26px;
    span {
        display: block;
        color: #71767D;
        font-family: 'azo-sans-web', sans-serif;
        font-size: 14px;
        line-height: 18px;
    }
}  
