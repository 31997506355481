
.differentiationMapContainer {
    width: 100%;
    height: calc(100% - 100px);
    box-sizing: border-box;
    min-width: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 40px;
    &.noXY {
        height: 100%;
        .map {
            width: 100%;
            height: 100%; 
        }
    }
    .axis {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span {
            display: block;
            height: 12px;
            width: 40px;
            color: #B5B9BF;
            font-family: 'azo-sans-web', sans-serif;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 16px;
            text-align: center;
        }
        &.yAxis {
            width: 50px;
            height: calc(100% - 50px);
            flex-direction: column;
        }
        &.xAxis {
            width: 100%;
            height: 50px;
            padding-left: 5px;
            padding-right: 20px;
            >span:nth-child(2) {
                margin-left: 70px;
            }
        }
    }
    .map {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            box-sizing: border-box;
            height: 1px;
            width: 100%;
            border: 0.5px solid #DFE2EA;
            top: 50%;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            box-sizing: border-box;
            height: calc( 100% + 40px );
            width: 1px;
            border: 0.5px solid #DFE2EA;
            top: -40px;
            left: 50%;
        }
        .differentiationItem {
            position: absolute;
            border-radius: 29px;
            background-color: #F1F2F7;
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
            color: #000000;
            font-family: 'azo-sans-web';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            white-space: nowrap;
            padding: 10px 25px;
            z-index: 1;
            cursor: pointer;
            &.logoImg, &.logoText {
                height: 45px;
                width: 45px;
                border-radius: 25px;
                background-color: #FFFFFF;
                padding: 0;
                z-index: 2;
                .icon {
                    height: 45px;
                    width: 45px;
                    border-radius: 25px;
                    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15)
                }
                >div {
                    height: 45px;
                    width: 45px;
                    border-radius: 25px;
                    color: #DFE2EA;
                    font-family: 'Silka-Bold', sans-serif;
                    font-size: 20px;
                    line-height: 50px;
                    text-align: center;
                    background-color: #000000;
                }  
            }
            &:hover {
                opacity: 0.6;
            }
        }
        .differentiationItem:hover + .tooltip{
            display: flex;
        }
    }
}