.userCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
    border-bottom: 1px solid #DFE2EA;
    position: relative;

    .titleContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .primaryTitle {
            color: #0062FF;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 24px;
            letter-spacing: -0.5px;
            line-height: 32px;
        }
    
        .secondaryTitle {
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
        }
    }

    .rightHeaderContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .extraInfo {
            font-family: 'Silka-Bold', sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: right;
              
            .status {
                color: #71767D;
            }
            .message {
                color: #0062FF;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;;
                }
            }
        }

        .button.cancelButton {
            padding: 16px 20px!important;
            margin: 0;
        }
    
        .optionsButton {
            width: 32px;
            height: 32px;
            margin-left: 25px;
            border: 1px solid transparent;
            border-radius: 32px;
            cursor: pointer;
        }
    }
}
