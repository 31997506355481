.donutChartCardContainer {
    width: 170px;
    height: 170px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .arc:hover {
        opacity: 0.5;
    }

    .donutChartOverlapContainer {
        position: absolute;
        top: 17px;
        left: 20px;
        width: 130px;
        height: 150px;
        border-radius: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // background-color: yellow;

        .donutChartTitle {
            color: #71767D;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 0.7rem;
            letter-spacing: 0.085rem;
            line-height: 0.875rem;
            text-align: center;
            >span {
                left: -60px;
                top: 20px;
            }
        }

        .advertisingdonutChartTitle{
            padding-top: 15px;
        }

        .boldText{
            font-weight: 500;
            color: #17181A;
        }

        .donutChartNumber {
            padding-top: 0.1875rem;
            color: #17181A;
            font-family: 'azo-sans-web', sans-serif;
            font-size: 2rem;
            font-weight: 700;
            letter-spacing: 0.0625rem;
            line-height: 2.625rem;
            text-align: center;
            padding-bottom: 0.1875rem;
        }

        .donutChartUpDownContainer {
            display: flex;
            padding-top: 0.475rem;

            .donutChartUpDownIcon {
                >span {
                    left: -60px;
                    top: 20px;
                }

                &.withPaddingTop {
                    padding-top: 5px;
                }
            }
            .advertisingImg{
                margin-top: 10px;
            }

            .donutChartUpDown {
                padding-right: 0.3125rem;
                //color: #1BA379;
                font-family: 'azo-sans-web', sans-serif;
                font-size: 1.2rem;
                font-weight: 700;
                line-height: 2rem;
                //&.negativeColor {
                //    color: #E51E00;
                //}
                //&.noChange {
                //    color: #71767D;
                //}

                .upDownSign {
                    color: #71767D;
                }
            }

        }
    }
}

.statTooltip{
    text-align: center;
    font-size: 12px;
    color: #71767D;
    font-family: "azo-sans-web", sans-serif;
    background-color: #DFE2EA;
    width: 100%;
    margin: 10px -10px -14px;
    padding: 10px;
}

.percentageContainer {
    display: flex;
    justify-content: space-between;

    .percentage {
        margin-right: 5px;
    }
}
