.stackedBarChart {
    width: 100%;
    padding-bottom: 50px;
    min-height: 400px;
    background-color: #ffffff;
    .topContainer {
        display: flex;
        height: 102px;
        margin-top: 5px;

        .selectContainer {
            display: flex;
            height: 100%;

            .mainSelectContainer {
                display: flex;
                height: 100%;
                align-items: center;
                padding-left: 40px;
                min-width: 240px;
                border-right: 1px solid #DFE2EA;
            }

            .secondarySelectContainer {
                display: flex;
                flex: 1 1;
                align-items: center;
                height: 100%;
                padding-left: 15px;
                // min-width: 500px;
            }

            .inputBox {
                min-width: 165px;
                height: 50px;
                box-sizing: border-box;
                border-radius: 2.5rem;	
                box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                padding: 13px 50px 20px 30px;	
                margin-right: 20px;
                // text-transform: capitalize;
                border: 1px solid #dfe2ea;
                img.ddArrow {
                    top: 18px !important;
                    right: 20px !important;
                    position: absolute;
                }
                .selectListContainer {
                    max-height: 247px;
                    top: 106%;

                    &.wider {
                        width: auto;
                    }

                    >.selectItem {
                        font-size: 12px;
                        padding: 1px 15px;
                        // text-transform: capitalize;

                        &.maxContent {
                            width: max-content;
                        }
                    }
                }
            }
        }

        .optionList {
            display: flex;
            flex: 1 1;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            height: 100%;

            .optionItem {
                margin-right: 15px;
                display: flex;
                align-items: center;
                color: #71767D;
                font-family: "azo-sans-web", sans-serif;
                font-size: 11px;
                letter-spacing: 0;
                line-height: 18px;
                text-transform: capitalize;

                .circleMarker {
                    height: 10px;
                    width: 10px;
                    margin-right: 8px;
                    border-radius: 5px;
                }
            }
        }
    }

    .barChartArea {
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        flex-direction: column;

        .barChartRow {
            display: flex;
            height: 90px;
            padding-right: 20px;
            align-items: center;

            .rowTitle {
                display: flex;
                flex-direction: column;

                .campaignRegion {
                    font-size: 14px;
                    color: #0062FF;
                    font-family: "azo-sans-web", sans-serif;
                }
            }

            &:first-child {
                height: 115px;
                background-color: #F2F4FA;
                border-radius: 4px;

                .titleBlock  {
                    align-items: center;
                    .titleContainer {
                        justify-content: center;
                    }
                    .statLetter {
                        margin-bottom: 0;
                    }
                }

                .chartBlock {
                    align-items: center;
                }
            }

            &.buttonContainer {
                height: 160px;
                .titleBlock {
                    // padding-left: 0;
                    align-items: center;
                    justify-content: center;
                }
            }

            .titleBlock {
                display: flex;
                align-items: flex-end;
                position: relative;
                padding-left: 15px;
                box-sizing: border-box;
                min-width: 245px;
                width: 245px;
                border-right: 1px solid #DFE2EA;
                &>div {
                    width: 100%;
                    display: flex;
                    align-items: center;

                }

                &:hover {
                    cursor: pointer;
                    .titleTooltip {
                        display: block;
                        width: auto;
                    }
                }

                .logoTitleHolder {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 85%
                }

                .brandLogo {
                    width: 26px;
                    height: 26px;
                    border-radius: 15px;
                    margin-right: 15px;

                    &.textLogo {
                      box-sizing: border-box;
                      background-color: #292D33;
                      color: #DFE2EA;
                      font-family: 'Silka-Bold', sans-serif;
                      font-size: 12px;
                      line-height: 12px;
                      text-align: center;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                }

                .titleContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 50px;
                    width: 75%;


                    .title {
                        color: #000000;
                        font-family: 'Silka-SemiBold', sans-serif;
                        font-size: 13px;
                        letter-spacing: 0;
                        line-height: 20px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .title:hover .tooltiptext {
                        opacity: 1;
                        visibility: visible;
                        text-overflow: clip;
                        white-space: normal;
                        word-break: break-all;
                        white-space: nowrap;
                    }
                    
                    .title .tooltiptext {
                        visibility: hidden;
                        min-width: 100px;
                        background-color: #edeff5;
                        color: #4c4d4e;
                        text-align: center;
                        border-radius: 6px;
                        padding: 4px 9px;
                        height: 23px;
                        position: absolute;
                        z-index: 1;
                        left: 10%;
                        top: -60%;
                        font-size: 12px;
                        font-weight: 200;
                        font-family: "azo-sans-web", sans-serif;
                        padding-top: 5px;
                        white-space: nowrap;
                    }

                    .blockValue {
                        color: #71767D;
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 18px;
                    }
                }


                .titleTooltip {
                    position: absolute;
                    z-index: 1;
                    top: 90px;
                    display: none;
                    padding: 20px;
                    padding-bottom: 12px;
                    flex-wrap: wrap;
                    width: 350px;
                    border: 1px solid #DFE2EA;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 12px 0 rgba(23,24,26,0.1);

                    .titleTooltipLabel {
                        // margin-right: 8px;
                        margin-bottom: 8px;
                        padding: 0 14px;
                        height: 36px;
                        border: 1px solid #DFE2EA;
                        border-radius: 29px;
                        background-color: #DFE2EA;
                        color: #000000;
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 36px;
                        text-align: center;
                        width: max-content;
                    }
                }
                .iconContainer {
                    cursor: pointer;
                    margin-left: -30px;
                    height: 50px;
                    width: 50px;
                    border-radius: 30px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 27px;
                    transition: all .3s ease-in-out;
                    &:hover {
                        box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
                    }
                }
                .statLetter {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 25px;
                    height: 25px;
                    border: #DFE2EA 1px solid;
                    border-radius: 50%;
                    color: #71767D;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .chartBlock {
                display: flex;
                align-items: flex-end;
                box-sizing: border-box;
                width: 100%;

                .diagramBlock {
                    display: flex;
                    width: 100%;

                    .diagramItemBlock {
                        position: relative;

                        &:last-child .diagramItem {
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }

                        &:hover {
                            .diagramTooltip {
                                display: block;
                            }
                        }

                        .itemTitleBlock {
                            position: absolute;
                            bottom: 50px;
                            display: flex;
                            width: 90%;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            color: #082734;
                            font-family: 'Silka-SemiBold', sans-serif;
                            font-size: 0.75rem;
                            letter-spacing: 0;
                            line-height: 14px;
                            text-transform: capitalize;

                            .itemTitle {
                                text-align: center;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }

                        .diagramTooltip {
                            display: none;
                            position: absolute;
                            z-index: 10;
                            bottom: 70px;
                            padding: 10px 10px;
                            box-sizing: border-box;
                            width: 200px;
                            border: 1px solid #DFE2EA;
                            border-radius: 4px;
                            background-color: #FFFFFF;
                            box-shadow: 0 2px 12px 0 rgba(23,24,26,0.1);
                            color: #000000;

                            .diagramTooltipTitle {
                                padding-bottom: 5px;
                                font-family: 'Silka-SemiBold', sans-serif;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 20px;
                                text-align: center;
                                border-bottom: 1px solid #DFE2EA;
                            }

                            .valuesBlock {
                                margin-top: 10px;
                                font-family: "azo-sans-web", sans-serif;
                                text-align: center;

                                .percentValue {
                                    font-size: 16px;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    line-height: 24px;
                                }
                                .filterName {
                                    color: #7D8188;
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 18px;
                                    text-transform: capitalize;
                                }
                                .absoluteValue {
                                    color: #46BCC6;
                                    font-size: 16px;
                                    font-weight: 500;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                }
                            }

                            .statTooltip{
                                text-align: center;
                                font-size: 11px;
                                color: #71767D;
                                font-family: "azo-sans-web", sans-serif;
                                background-color: #DFE2EA;
                                margin: 5px -10px -10px;
                                padding: 10px;
                                span {
                                    color: #000;
                                    margin: 5px 0;
                                }
                            }
                        }

                        .diagramItem {
                            // flex: 1;
                            // position: relative;
                            height: 45px;
                            color: #FFFFFF;
                            font-family: "azo-sans-web", sans-serif;
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 50px;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &:hover {
                                cursor: pointer;
                                opacity: 0.5;
                            }

                            .statLetter {
                                display: inline-flex;
                                align-items: center;
                                justify-content: space-around;
                                width: 25px;
                                height: 25px;
                                margin: 0 0 0 5px;
                                border: #fff 1px solid;
                                border-radius: 50%;
                                background-color: transparent;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
