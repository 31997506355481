.miniBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.875rem;
    position: fixed;
    left: 140px;
    bottom: 30px;
    transition: .5s ease-in-out;
    z-index: 5;
    .viewButtonContainer{
        margin-left: 10px;
        background-color: white;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-out 0s;
    }
    &.menuExpand {
        left: 340px;
    }
    @media screen and (max-width: 812px) {
        position: fixed;
        left: 30px;
        bottom: 36px;
    }

    .toggleContainer {
        display: flex;
        flex-direction: row;
        height: 50px;
        width: 140px;
        border-radius: 25px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
        transition: all 0.3s ease-out 0s;
        &:hover {
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2)
        }                
        .toggleIconContainer {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .rightBorder {
            border-right: 1px solid #DFE2EA;
        }
    }

    .toggleContainerHide {
        display: none;
    }
}    