.segmentHeader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .SegmentTitle {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 25px;
        box-sizing: border-box;

        .segmentName {
            color: #000000;
            font-size: 12px;
            line-height: 1.5rem;
            font-family: 'Silka-SemiBold', sans-serif;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 5px;
        }

        .segmentCount {
            color: #71767D;
            font-size: 12px;
            line-height: 1.5rem;
            text-align: right;
        }

        &:hover {
            >div {
                &.segmentName {
                    background-color: #ffffff;
                }

                &.segmentCount {
                    right: 1.5rem;
                }
            }
        }
    }

    .filterList {
        margin: 0 auto;
        width: 100%;
        height: calc(100% - 1.5rem);
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px 0;
        cursor: pointer;

        .filterItem {
            overflow: hidden;
            font-size: 10px;
            line-height: 15px;
            padding: 4px 10px;
            margin: 1px 8px 12px 0;
            text-overflow: ellipsis;
        }

        &:hover {
            margin-left: -20px;
            overflow: visible;
            height: auto;
            width: fit-content;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
            padding: 20px 20px 10px;
        }

        >div {

            &.moreButton {
                cursor: pointer;
                padding: 0;
                line-height: 33px;
                font-size: 14px;
                margin: 0 8px 3px 0;
                white-space: nowrap;
            }

            &.moreFilters {
                width: calc(100% - 40px);
                max-width: calc(100% - 40px);
                padding: 20px;
                top: calc(100% - 20px);
            }
        }
    }
}