.pageContainer .contentContainer .rightContainer .pageContent.summaryPage {
    display: block;
    min-height: calc( 100vh - 70px);
    //overflow-x: auto;
    scrollbar-width: thin;
    box-sizing: border-box;

    .chartHeaderContainer {
        width: 100%;
        height: 75px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 24px 0px 24px 25px;

        .titleContainer {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            .headerLabel {
                font-family: 'Silka-SemiBold';
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 1.5px;
                line-height: 16px;
                color: #000000;
                cursor: pointer;
            }
            .sSize {
                // font-family: 'azo-sans-web';
                font-weight: 400;
                font-size: 14px;
                color: #71767D;
            }
        }
        .advertisingWarning {
            font-size: 12px;
        }
        &.equityTitle {
           // position: absolute;
            box-sizing: border-box;
            height: 70px;
            top: 95px;
            z-index: 3;
            @media only screen and (max-width:1140px) {
                top: 1rem;
            }
        }
    }

    .mainContainer{
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
        background-color: #F5F7FA;
    }
    .middleContainer {
        width: 100%;
        background-color: #ffffff;
        border-bottom: 1px solid #dfe2ea;
        .middleCardContainer {
            height: 100%;
            display: flex;
            overflow-y: hidden;
            @media only screen and (max-width: 812px) {
                width: 100%;
                overflow-x: auto;
            }
            .equityContainer {
                width: 480px;
                min-width: 480px;
                //padding-top: 50px;
                box-sizing: border-box;
                position: unset;
                @media only screen and (max-width:1140px) {
                    position: relative;
                    // padding-top: 80px;
                }
                @media only screen and (max-width: 812px) {
                    width: 100%;
                    min-width: 100%;
                }

                .donutContainer {
                    height: 200px;
                }

                .listContainer {
                    overflow-y: auto;
                    max-height: 300px;

                    .itemBgColor {
                        background-color: #F6F7F8;
                    }

                    .itemContainer {
                        height: 60px;
                        width: 100%;
                        padding-left: 30px;
                        padding-right: 50px;
                        align-items: center;
                        justify-content: space-between;
                        color: #000000;
                        box-sizing: border-box;
                        display: flex;
                        &:first-child {
                            .itemData .upDownIcon >span {
                                top: 10px;
                                bottom: auto;
                            }
                        }
                        .itemTitle {
                            font-size: 14px;
                            line-height: 20px;
                            // font-family: 'azo-sans-web', sans-serif;
                            font-weight: 400;
                            &.current {
                                font-weight: 700;
                            }
                        }

                        .itemData {
                            display: flex;
                            align-items: center;
                            justify-items: flex-end;
                            .figure {
                                font-size: 16px;
                                line-height: 18px;
                                text-align: right;
                                // font-family: 'azo-sans-web';
                                font-weight: 700;

                            }
                            .upDownIcon {
                                line-height: 100%;
                                width: 24px;
                                >span {
                                    width: 350px;
                                    right: 15px;
                                    bottom: 0;
                                }
                            }
                            .icon {
                                width: 0.875rem;
                                height: 1rem;
                                margin-left: 10px;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            .trendsContainer {
                width: calc(100% - 480px);
                min-width: 700px;
                height: 100%;
                box-sizing: border-box;
                padding: 25px;
            }
        }
    }

    .summaryBottomContainer {
        width: 100%;
        background-color: #ffffff;
        overflow-x: auto;
        .bottomCardContainer {
            width: 100%;
            // min-width: 1200px;
            display: flex;
            background-color: #F5F7FA;
            @media only screen and (max-width: 812px) {
                width: 300vw;
                min-width: 300vw;
            }
            .funnelCardContainer {
                width: 540px;
                min-width: 540px;
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                border-right: 1px solid #dfe2ea;
                background-color: #ffffff;
                @media only screen and (max-width: 812px) {
                    width: 100vw;
                    min-width: 100vw;
                }

                .chartContainer {
                    width: 100%;
                    //min-height: 640px;
                    max-height: 640px;
                    display: flex;

                    .cardHeaderContainer {
                        display: none;
                    }

                    .scrollContainer {
                        margin-top: 30px !important;
                    }
                }
            }

            .bubbleCardsContainer {
                width: calc(100% - 540px);
                display: flex;
                box-sizing: border-box;
                @media screen and (max-width: 812px) {
                    width: 200vw;
                }
                .bubbleCardContainer {
                    min-width: 369px;
                    //min-height: 640px;
                    //max-height: 640px;
                    width: 50%;
                    position: relative;
                    // padding-top: 70px;
                    border-right: 1px solid #DFE2EA;
                    background-color: #ffffff;
                    z-index: 1;
                    // background-color: red;
                    @media screen and (max-width: 812px) {
                        min-width: 100vw;
                        width: 100vw;
                    }

                    .cardHeaderContainer {
                        display: none;
                    }
                    & .peopleValue {
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 12px;
                        font-weight: 700;
                        color: #000000;
                        padding-left: 25px;
                        & > .recallValue {
                            color: rgb(115, 204, 239);
                        }
                    }

                }

            }

        }
    }

}
