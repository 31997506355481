$text-color-error: #da1e28;

.form-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.275rem;

    font-size: 0.9rem;
}

.form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.form-label-wrapper,
.form-input-wrapper {
    //min-width: 200px;
    flex: 1 1 auto;
    box-sizing: border-box;
    font-size: inherit;
}

.form-label-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    min-width: 150px;
}

.form-label {
    font-weight: 600;
    box-sizing: border-box;
}

.form-input-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 350px;
    width: 100%;
    flex-direction: column;
    gap: 0.2rem;
}

.form-input-block {
    position: relative;
    height: 100%;
}
  
.form-input:focus-visible {
    outline: 1px solid #2684FF; 
}

.css-1jqq78o-placeholder {
    color: #585656 !important;
    font-family: "azo-sans-web", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.form-input {
    padding: 1.275rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #DFE2EA !important;
    color: #000000;

    &:disabled {
        background-color: #F3F4F7;
        border: 1px solid #F3F4F7 !important;
    }

    &:hover {
        &:not(:disabled) {
            border-color: hsl(0, 0%, 70%) !important;
        }
    }
    

    &:focus, &:active {
        &:not(:disabled) {
            border:1ps solid #2684ff !important;
        }
    }

    &.warning {
        border-color: $text-color-error !important;
    }
}

.form-input-message {

    &.warning {
        color: $text-color-error;
    }
}

.form-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.4rem;
    margin-left: 0.5rem;

    img {
        width: 100%;
    }
}

.form-input-icon--overlay {
    position: absolute;
    top: 0;
    right: 0.8rem;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    &:not(.override-disabled) {
        cursor: pointer;
    }
}

.icon{
    stroke-width: 0.5;

    &:hover {
        stroke-width: 1.2;

        .form-input-icon--overlay.override-disabled & {
            stroke-width: 0.5;
        }
    }
}

.form-upload-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;

    .fileContainer {
        box-shadow: unset !important;
    }

    .form-upload__detail {
        max-width: 250px;
        position: absolute;
        z-index: 5;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-image-wrapper {
        position: absolute;
        height: 250px;
        width: 250px;
        z-index: 2;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

        .form-image {
            max-width: 250px;
            width: 100%;
            border-radius: 50%;
        }
    }
}

.form-actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.form-btn {
    padding: 1rem 1.2rem;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease-out 0s;

    &:not(:disabled) {
        cursor: pointer;
    }

    &.default {
        background-color: white;
        border: 1px solid #78909c;
        color: #78909c;
        box-shadow: none;

        &:hover {
            background: hsl(200deg 15.38461538461539% 44.117647058823536%);
            border: 1px solid hsl(200deg 15.38461538461539% 44.117647058823536%);
            box-shadow: none;
            color: #FFFFFF;
        }

        &:disabled {
            background-color: white;
            border-radius: 0;
            color: #78909c
        }
    }

    &.primary {
        background-color: #0062ff;
        border: 1px solid #0062ff;
        color: #FFFFFF;
        box-shadow: none;

        &:hover {
            background: hsl(216.94117647058823deg 100% 40%);
            border: 1px solid hsl(216.94117647058823deg 100% 40%);
            box-shadow: none;
        }

        &:disabled {
            background-color: rgba(0, 0, 0, 0.12);
            border: 1px solid rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
            box-shadow: none;
            // opacity: 0.5;
        }
    }
}