.pageContainer {
    width: 100%;
    height: 100%;
    .loadingLayer {
        position: fixed;
        top: 52px;
    }
    .headerContainer {
        position: sticky;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        z-index: 1000;
    }
    .contentContainer {
        width:100%;
        display: flex;
        background-color: #F5F7FA;
        padding: 0;
        margin: 0;

        @media screen and (max-width: 812px) {
            min-width: 300px;
        }

        .leftContainer {
            position: sticky;
            padding: 0;
            top: 54px;
            height: 100%;
            width: 90px;
            height: calc( 100vh - 54px );
            box-sizing: border-box;
            transition: .5s ease-in-out;
            background-color: #FFFFFF;
            z-index: 2;
            .menuText, .iconCollapse {
                display: none;
            }
            .iconExpand {
                display: block;
            }
            @media screen and (max-width: 812px) {
                position: fixed;
                background-color: #FFFFFF;
                z-index: 9;
                width: 0;
                .menuText, .iconCollapse {
                    display: block;
                }
                &.openMenu {
                    width: 320px;
                }
            }
        }
        .rightContainer {
            width: calc( 100% - 90px );
            min-height: calc( 100vh - 70px );
            box-sizing: border-box;
            transition: .5s ease-in-out;
            background-color: #ffffff;
            .quest-suitePage, .reportsPage, .viewPage {
                background-color: rgb(245, 247, 250);
            }
            .pageContent {
                width: 100%;
                height: 100%;
                display: flex;
                position: relative;
                .filterBox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 20px;
                    gap: 17px;
                    .tabsContainer {
                        border-bottom: none;
                    }
                    .filterTab {
                        //min-width: 146px;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        width: 86px;
                        gap: 5px;
                        display: flex;
                        .selectListContainer {
                            width: auto;
                            border: 1px solid #DFE2EA;
                            border-radius: 4px;
                        }
                        img {
                            margin-top: 6px;
                        }
                    }
                }
                &.adminPage {
                    .groupTable {
                        width: 100%;
                        margin-top: -1px;
                        .tableTitle {
                            width: 100%;
                            box-sizing: border-box;
                            padding: 25px 40px;
                            border-top: 1px solid #DFE2EA;
                            border-bottom: 1px solid #DFE2EA;
                            display: flex;
                            justify-content: space-between;
                            color: #000000;
                            font-family: 'azo-sans-web', sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 1px;
                            line-height: 16px;
                            .button {
                                margin: -15px 0;
                            }
                        }
                    }
                    .button {
                        width: auto;
                        height: 50px;
                        font-family: "Silka-SemiBold", sans-serif;
                        font-size: 16px;
                        padding: 15px 40px;
                    }
                }
                .mainContent {
                    width: 100%;
                    transition: .5s ease-in-out;
                    .reportsPage {
                        background-color: rgb(245, 247, 250);
                        padding: 30px;
                        gap: 10px;
                    }
                    @media only screen and (min-width: 813px) {
                        &.filterOpen {
                            width: calc( 100% - 305px );
                        }
                    }
                    // hbar scss
                    .horizontalMenuBarContainer {
                        justify-content: space-between;
                        position: sticky;
                        top: 70px;
                        width: 100%;
                        height: 80px;
                        background-color: #FFFFFF;
                        display: flex;
                        border-bottom: 1px solid #DFE2EA;
                        margin:0;
                        z-index: 2;

                        @media only screen and (max-width: 1200px) {
                            height: 170px;
                            display: flex;
                            flex-direction: column;
                        }

                        @media only screen and (max-width: 812px) {
                            height: 80px;
                            flex-direction: row;
                            &.periodClicked {
                                height: 170px;
                                flex-direction: column;
                                transition: .5s ease-in-out;
                                overflow: visible;
                            }

                        }
                        >.periodIcon {
                            display: none;
                            @media screen and (max-width: 812px) {
                                display: block;
                                cursor: pointer;
                                top: 25px;
                                right: 68px;
                                position: absolute;
                            }
                        }
                        .leftHorizontalMenuBarContainer {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding-right: 1.875rem;
                            position: fixed;
                            left: 140px;
                            bottom: 30px;
                            transition: .5s ease-in-out;
                            &.menuExpand {
                                left: 340px;
                            }
                            @media screen and (max-width: 813px) {
                                position: fixed;
                                left: 30px;
                                bottom: 36px;
                            }
                        }

                        .middleHorizontalMenuBarContainer {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            border-right: 1px solid #DFE2EA;
                            margin: 0 0;
                            padding: 0 20px 0 0;
                            flex: 1;
                            box-sizing: border-box;
                            @media screen and (max-width: 1200px) {
                                width: calc(100vw - 110px);
                                border-top: 1px solid #DFE2EA;
                                padding: 14.5px 15px 14.5px 0;
                                position: absolute;
                                left: 0;
                                top: 90px;
                                justify-content: flex-end;
                            }
                            @media screen and (max-width: 812px) {
                                width: 100%;
                                height: 0;
                                border-right: none;
                                border-top: none;
                                display: none;
                                &.periodClicked {
                                    height: 90px;
                                    border-top: 1px solid #DFE2EA;
                                    padding: 14.5px 0;
                                    top: 80px;
                                    justify-content: center;
                                    transition: .5s ease-in-out;
                                    overflow: visible;
                                    display: flex;
                                }
                            }
                            .periodIconContainer {
                                display: block;
                                padding: 0;
                                @media only screen and (max-width: 812px) {
                                    display: none;
                                    &.periodClicked {
                                        display: flex;
                                        justify-content: center;
                                        padding: 0;
                                        transition: .5s ease-in-out;
                                    }
                                }
                            }
                        }
                        .rightHorizontalMenuBarContainer {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 3.75rem;
                            padding: 0 1rem;
                            cursor: pointer;
                            @media screen and (max-width: 1200px) {
                                height: 90px;
                                justify-content: center;
                                border-left: 1px solid #DFE2EA;
                                padding: 0 1rem;
                                position: absolute;
                                right: 0;
                            }
                            @media only screen and (max-width: 812px) {
                                height: 80px;
                                width: 50px;
                                box-sizing: border-box;
                                position: unset;
                                &.periodClicked {
                                    position: absolute;
                                }
                            }

                            .filterIcon {
                                width: 1.75rem;
                                height: 1.75rem;
                            }
                        }

                        .horizontalMenuIcon {
                            width: 1.375rem;
                            height: 1.5rem;
                        }

                        .toggleContainer {
                            display: flex;
                            flex-direction: row;
                            // justify-content: space-around;
                            // align-items: center;
                            height: 50px;
                            width: 140px;
                            border-radius: 25px;
                            background-color: #FFFFFF;
                            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
                            transition: all 0.3s ease-out 0s;
                            &:hover {
                                box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2)
                            }
                            .toggleIconContainer {
                                display: flex;
                                flex: 1;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                            }

                            .rightBorder {
                                border-right: 1px solid #DFE2EA;
                            }
                        }

                        .toggleContainerHide {
                            display: none;
                        }
                    }
                    // hbar scss
                    .dataItemsContainer {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: stretch;
                        justify-content: flex-start;
                        scrollbar-width: thin;
                        min-height: 100%;
                        &.loading {
                            position: relative;
                            height: calc( 100vh - 230px );
                            overflow: hidden;
                        }
                        .barChartContainer {
                            @media only screen and (max-width: 812px) {
                                min-width: unset;
                                box-sizing: border-box;
                            }
                            .equityRightContainer {
                                @media only screen and (max-width: 812px) {
                                    min-width: unset;
                                }
                            }
                        }
                        .drag{
                            position: relative;
                            min-width: 300px;
                            max-width: 300px;
                            flex-basis: min-content;
                        }
                        .cardContainer {
                            position: static;
                            z-index: 1;
                            padding-bottom: 110px;
                            margin-right: 16px;
                            background-color: #ffffff;
                            top: 1px !important;
                            &.equityCompetitiveContainer {
                            }
                            &.advertisingCompetitiveContainer{
                                width: 350px;
                                .emptyContent {
                                    width: auto;
                                }
                            }
                            &.emotionalContainer {
                                width: 360px;
                                .emptyContent {
                                    width: auto;
                                }
                            }
                            &.funnelChartContainer {
                                width: 480px;
                            }
                            .cardHeaderContainer {
                                position: sticky !important;
                                top: 0;
                                width: 100%;
                                height: 55px!important;
                                box-sizing: border-box;
                                background-color: #FFFFFF;
                                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 0 1rem;
                                z-index: 2;

                                .brandTitle {
                                    .brandLogoIcon {
                                        width: 2rem;
                                        height: 2rem;
                                        border-radius: 1.5625rem;
                                        object-fit: cover;
                                        margin-right: 0.75rem;
                                    }

                                    .brandDefaultLogo {
                                        width: 2rem;
                                        width: 2rem;
                                        min-height: 2.125rem;
                                        border-radius: 1.5625rem;
                                        margin-right: 0.75rem;
                                    }

                                    .brandName {
                                        // width: calc(100% - 2.875rem );
                                        color: #000000;
                                        font-family: 'Silka-SemiBold', sans-serif;
                                        font-size: 0.8rem;
                                        line-height: 1rem;
                                    }

                                }
                                .headerTitle {
                                    color: #000000;
                                    font-family: 'Silka-SemiBold', sans-serif;
                                    font-size: 1.2rem;
                                    line-height: 1.5rem;
                                    padding-left: 0.75rem;
                                }

                                .headerLogo {
                                    min-width: 2.125rem;
                                    max-width: 2.125rem;
                                    min-height: 2.125rem;
                                    border-radius: 1.5625rem;
                                    object-fit: scale-down;
                                }
                                .brandContainer {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .brandDefaultIcon {
                                        box-sizing: border-box;
                                        min-width: 3.125rem;
                                        max-width: 3.125rem;
                                        min-height: 3.125rem;
                                        border-radius: 1.56rem;
                                        border: 2px solid #FFFFFF;
                                        background-color: #292D33;
                                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
                                        color: #DFE2EA;
                                        font-family: 'Silka-Bold', sans-serif;
                                        font-size: 1.25rem;
                                        line-height: 1.5rem;
                                        text-align: center;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                }

                                .value {
                                    color: #71767D;
                                    font-family: 'azo-sans-web', sans-serif;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    text-align: right;
                                }
                            }
                        }
                        @media screen and (max-width: 812px) {
                            //flex-direction: column;
                            overflow: auto;
                            width: 100vw;
                            .cardContainer{
                                width: 100%;
                                min-width: 100%;
                                // max-width: 600px;
                            }
                        }
                    }
                    .scrollBarHidden {
                        overflow-x: hidden;
                    }

                    .scrollWrapper {
                        // overflow: scroll;
                        background-color: #F5F7FA;
                        transition: height 0.2s;
                    }
                }
                .filtersContainer {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    right: 0;
                    z-index: 3;
                    overflow-x: hidden;
                    overflow-y: auto;
                    width: 0px;
                    height: calc(100vh - 56px);
                    border-left: 1px solid #DFE2EA;
                    box-shadow: -2px 0 12px 0 rgba(0,0,0,0.1);
                    transition: width .5s ease-in-out;
                    background-color: #ffffff;
                    &.filterOpen {
                        width: 305px;

                        @media screen and (max-width: 813px) {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            .filterCloseIcon {
                                visibility: visible;
                            }
                        }

                    }
                    .filterCloseIcon {
                        position: fixed;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        right: 20px;
                        bottom: 20px;
                        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                        background-color: #FFFFFF;
                        height: 50px;
                        width: 50px;
                        border-radius: 25px;
                        z-index: 6;
                        cursor: pointer;
                        visibility: hidden;
                    }
                    .filtersBackground {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #FFFFFF;
                        margin: 0 0;
                        .selectFilterContainer {
                            display: flex;
                            flex-direction: row;
                            height: 2.5rem;
                            margin: 0 1.875rem;
                            margin-top: 1.25rem;
                            border: 1px solid #DFE2EA;
                            border-radius: 4px;

                            .searchIconContainer {
                                padding: 0 1.25rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .prefixIcon {
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }
                        }
                    }
                }
                input {
                    border: none;
                }
            }
            @media screen and (max-width: 812px) {
                width: 100%!important;
                .tabsContainer {
                    width: 100%!important;
                }
            }
        }

        .mobileOverlay {
            display: none;
            @media screen and (max-width: 812px) {
                position: fixed;
                display: block;
                top: 70px;
                left: 0;
                bottom: 0;
                width: 0;
                background-color: rgba(0,0,0,0.6);
                transition: all .5s ease-in-out;
                z-index: 8;
                &.openMenu {
                    width: 100%;
                }
            }
        }
        &.expand {
            .leftContainer {
                width: 270px;
                .menuContainer {
                    width: 225px;
                }
                .bottomContainer {
                    width: 270px;
                }
                .menuText, .iconCollapse, .copyright {
                    display: block;
                }
                .iconExpand {
                    display: none;
                }
                @media screen and (max-width: 813px) {
                    width: 0;
                    &.openMenu {
                        width: 320px;
                    }
                }
            }
            .rightContainer {
                width: calc( 100% - 270px );
                .tabsContainer {
                    width: calc( 100vw - 320px);
                }
                @media screen and (max-width: 1200px) {
                    .pageContent .mainContent .horizontalMenuBarContainer .middleHorizontalMenuBarContainer {
                        width: calc( 100vw - 320px );
                    }
                }
            }
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
}
