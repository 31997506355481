.listTable {
    width: 100%;
    min-width: 1600px;
    box-sizing: border-box;
    padding-bottom: 60px;
    background-color: #FFFFFF;
    >div {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: #000000;
        font-family: 'azo-sans-web', sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
        >div {
            margin: 0 5px;
        }
        &.tableHeader {
            color: #71767D;
            padding: 25px 0 25px 35px;
            border-bottom: 1px solid #DFE2EA;
            border-top: 1px solid #DFE2EA;
            .headerCell {
                display: flex;
                align-items: center;
            }
            .sortingIcon {
                margin-left: 10px;
                cursor: pointer;

                &--rotated {
                    transform: rotate(180deg);
                }
            }
        }
        &.tableRow {
            min-height: 61px;
            position: relative;
            padding-left: 35px;
            // padding: 20px 0 20px 35px;
            display: flex;
            align-items: center;
            &:nth-child(2n+1) {
                background-color: #F4F5F7;
            }
            &.rowClickable {
                cursor: pointer;
            }
            .rowCell {
                overflow: hidden;
                overflow-wrap: break-word;
                // display: flex;
                align-items: center;
                &.rowCellClickable {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .cellTrail {
                    color: #71767D;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 15px;
                    letter-spacing: 0;
                }
                .cellTrail:hover .alLlist {
                    visibility: visible;
                }

                .alLlist {
                    visibility: hidden;
                    position: absolute;
                    z-index: 1;
                    padding: 10px 20px 20px;
                    color: #000000;
                    font-size: 16px;
                    top: 70px;
                    box-sizing: border-box;
                    border: 1px solid #DFE2EA;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                }
            }
            .brandName {
                font-size: 16px;
                color: #000000;
            }
            .hoverIcon {
                display: block;
                visibility: hidden;
                margin: auto 30px auto 0; 
                &.primarySetting {
                    position: absolute;
                    right: 30px;
                    margin-right: 0px;
                }
            }
            &:hover {
                cursor: pointer;
                .cellClickable {
                   text-decoration: underline;
                }
            }
            &:hover .hoverIcon {
                visibility: visible; 
            }
            &.primarySetting{
                position: relative;
                .settingAction {
                    display: none;
                    position: absolute;
                    top: 60%;
                    right: 0;
                    box-sizing: border-box;
                    height: 56px;
                    width: 202px;
                    border: 1px solid #DFE2EA;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
                    color: #0062FF;
                    font-family: 'azo-sans-web', sans-serif;
                    font-size: 16px;
                    line-height: 36px;
                    z-index: 1;
                    padding: 10px 16px;
                }
                &.setted .settingAction {
                    color: #E51E00; 
                }
                &:hover {
                   .settingAction.show {
                        display: block;
                    }
                }
            }
        }
    }
}
