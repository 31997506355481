.inputBox {
  float: right;
  display: flex;
  min-width: 80px;
  border: 1px solid #fff;
 
    &.disabled {
      background-color: #F3F4F7;
    }

    img.ddArrow {
      transition: all 0.5s; 
      width: 13px;
      height: 13px;
      align-items: center;
    }

    .inputLabelText {
      font-size: 13px;
      letter-spacing: 0.02rem;
      padding-right: 3px;
      white-space: nowrap;
      font-weight: 500;
      // color: #344054;
      line-height: 20px;
      letter-spacing: -0.1px;
    }

    &:hover {
      cursor: pointer;
    }

    .selectListContainer {
      position: absolute;
      top: 100%;
      display: none;
      left: 0;
      width: 100%;
      min-height: 23px;
      max-height: 140px;
      overflow: auto;
      box-sizing: border-box;
      border: 1px solid #DFE2EA;
      background-color: #ffffff;
      z-index: 20;
      border-radius: 4px;
      padding: 5px 5px;
      margin: 4px 0;

      &.openTop {
        top: unset;
        bottom: 100%;
      }
      >.selectItem {
        height: 35px;
        width: 100%;
        box-sizing: border-box;
        font-family: "azo-sans-web";
        font-size: 14px;
        padding: 4px 20px; 
        cursor: pointer;
        display: flex;
        font-weight: 500;
        color:#344054;
        // align-items: center;
        // justify-content: center;

        &.selected {
          color: #7556D9 !important;
            &:hover {
              background-color: #F9FAFB !important;
          }
        }

        &:hover {
          background-color: #F9FAFB;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
    
    &.open { 
      border: 1px solid transparent !important;
      border-radius: 3px;
      img.ddArrow {
        transform: rotate(-180deg);
      }
      .selectListContainer {
        display: block;
      }
      &.disabled {
        border: 1px solid #DFE2EA;
      }
    }
  
    .placeholder {
      padding: 0;
      color: #B5B9BF;
      font-family: "azo-sans-web", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
}
