.profile {
    overflow-x: auto;
    .brandDefault {
        width: 30px;
        height: 30px;
        border-radius: 16px;
        border: 2px solid #FFFFFF;
        background-color: #292D33;
        color: #DFE2EA;
        font-family: 'Silka-Bold', sans-serif;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modalBody{
        height: 695px;
        width: 480px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.2);
    }
    .profileContent {
        //display: flex;
        //justify-content: space-between;
        border-top: 1px solid #DFE2EA;
        .zeroImgContainer {
            margin-top: 41px;
            margin-bottom: 32px;
            display: flex;
            justify-content: center;
        }

        .zeroImg {
            width: 560px;
            height: 454px;
        }

        .zeroTitle {
            color: #000000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 24px;
            letter-spacing: -0.5px;
            line-height: 32px;
            text-align: center;
        }

        .zeroMessage {
            display: flex;
            justify-content: center;
            span {
                width: 500px;
                color: #71767D;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
        }

        .profileContainer {
            width: 55.5%;
            height: calc(100vh - 152px);
            display: flex;
            flex-direction: column;

            .profileTable {
                width: 100%;
                height: 50%;

                .profileTableData {
                    overflow-x: hidden;
                    height: calc(100% - 70px);
                }

                .listTable {
                    min-width: unset;
                    padding-bottom: 0;

                    .totalCompletesRow {
                        background-color: #F0F6FF !important;
                        border: 1px solid rgba(0,98,255,0.2);
                        font-weight: 500;
                        color: #000000;
                    }
                }
            }
        }

        .profileTable {
            width: 55.5%;
            height: calc(100vh - 233px);
            box-sizing: border-box;
            border-left: 1px solid #DFE2EA;
            
            &.groupProfile {
                overflow: auto;
            }

            .profileTableHeader {
                display: flex;
                height: 70px;
                justify-content: space-between;
                align-items: center;
                padding-left: 40px;
                padding-right: 30px;
            }

            .profileTableTitle {
                color: #0062FF;
                font-family: 'Silka-Bold', sans-serif;
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 14px;
                text-transform: uppercase;
              }

            .groupOptionsButton {
                cursor: pointer;
            }

            .optionGroupDropdown {
                top: 40px;
                right: 20px;
            }

            .searchGroupPanel {
                display: flex;
                position: relative;

                .selectInputContainer {
                    width: 400px;
                    height: 50px;
                    box-sizing: border-box;
                    border: 1px solid #DFE2EA;
                    border-radius: 4px 4px 0 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
            
                    .searchIconContainer {
                        padding: 0 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
            
                        .prefixIcon {
                            height: 20px;
                        }
                    }
            
                    input {
                        width: 100%;
                        font-size: 14px;
                    }
                }

                .button.addFormButton {
                    padding: 16px 20px!important;
                    margin: 0;
                    margin-left: 10px;
    
                    &.disabled {
                        opacity: 0.5;
                        &:hover {
                            color: #71767D;
                        }
                    }
                }
            }
        }
    }

    .contractList {
        position: relative;
        width: auto;
        display: flex;
        flex-wrap: wrap;
        padding: 50px 40px;
        gap: 30px;
        // max-width: calc(100% - 350px);
        
        .contractItem {
            box-sizing: border-box;
            width: 330px;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            transition: all 0.5s;
            cursor: pointer;

            &:hover {
                transform: translate(0px, -10px);
                box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1);
            }

            .header {
                height: 84px;
                padding: 0 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: rgba(223,226,234,0.2);
                border-bottom: 1px solid #DFE2EA;

                .titleBlock {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        color: #000000;
                        font-family: 'Silka-Bold', sans-serif;
                        font-size: 20px;
                        letter-spacing: -0.5px;
                        line-height: 32px;
                        display: inline-block;
                        width: 150px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }

                    .datesBlock {
                        display: flex;
                        justify-content: flex-start;

                        .dateIcon {
                            height: 14px;
                            width: 14px;
                        }

                        .dates {
                            margin-left: 7px;
                            color: #71767D;
                            font-family: "azo-sans-web", sans-serif;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 16px;
                        }
                    }
                }
                .statusBlock {
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 16px;
                }
            }

            .contractBrandList {
                padding: 24px;
                padding-bottom: 8px;

                .contractBrandItem {
                    height: 30px;
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;

                    .brandLogo {
                        height: 30px;
                        width: 30px;
                        border: 1px solid transparent;
                        border-radius: 15px;
                    }

                    .brandName {
                        margin-left: 12px;
                        color: #000000;
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 18px;
                    }

                    .brandNumber {
                        color: #71767D;
                    }

                    .brandNumberIcon {
                        height: 30px;
                        width: 30px;
                        background-color: #DFE2EA;
                        border: 1px solid transparent;
                        border-radius: 15px;
                        color: #000000;
                        font-family: 'Silka-Bold', sans-serif;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 30px;
                        text-align: center;   
                    }
                
                }
            }
        }
    }
}
.geoBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
    height: 44px;
    background-color: rgba(223,226,234,0.4); 
    font-family: "azo-sans-web";
    color: #71767D;
   .geoIcon {
        height: 13px;
        width: 16px;
    }

    .dates {
        margin-left: 7px;
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }
}
.profileSuggestionsContainer {
    border-radius: 0 0 4px 4px;
    background-color: #FFFFFF;
    border: 1px solid #DFE2EA;
    padding: 0;
    margin: 0 auto 0;
    width: 400px;
    max-height: 30vh;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    z-index: 1;
    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        cursor: pointer;
        height: 60px;
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        &:hover {
            background-color: #F2F4F7;
        }

        .suggestTitle {
            color: #000000;
            font-family: "azo-sans-web", sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px;
        }

        .region {
            color: #0062FF;;
        }
    }
}

.addContractContaier {
    // position: absolute;
    // top: 50px;
    // right: 20px;
    box-sizing: border-box;
    min-height: 382px;
    width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DFE2EA;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
}

.addContractBlock {
    display: flex;
    flex-direction: column;
    color: #71767D;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-family: "azo-sans-web", sans-serif;
    justify-content: center;
    align-items: center;

    .iconContainer {
        cursor: pointer;
        height: 70px;
        width: 70px;
        border-radius: 35px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 27px;
        transition: all .3s ease-in-out;

        .plusIcon {
            height: 26px;
            width: 26px;
        }

        &:hover {
            box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
        }
    }
    .createLabel {
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
    }

    .updateContractBottom{
        
    }
}
.profileForm{
.brandDefault {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    border: 2px solid #FFFFFF;
    background-color: #292D33;
    color: #DFE2EA;
    font-family: 'Silka-Bold', sans-serif;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
}