.toaster-container {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 1000
}

.toaster {
	background: #fff;
	background-clip: padding-box;
	background-position: 15px;
	background-repeat: no-repeat;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
	border: 1px solid rgba(0,0,0,.1);
	border-radius: .25rem;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	margin-bottom: 15px;
	max-height: 100px;

	color: #000;
	opacity: 1;

	width: 350px;
	max-width: 100%;
	font-size: .875rem;
}

.toaster:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.toast {
	height: 50px;
	width: 365px;
	color: #fff;
	padding: 20px 15px 10px 10px;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);

	.toast-icon {
		max-width: 20px;
		width: 100%;

		margin-right: .5rem;
	}

	.header-title {
		margin-right: auto!important;
		line-height: 0;
	}
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word;
    background-color: #fbfbfb;
}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}
