.pageNav {
    position: fixed;
    z-index: 0;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;

  .pageNumber, .current {
    padding: 0 20px;
  }

    >ul {
      font-size: 16px;
      font-weight: normal;
      color: #0062FF;
      list-style: none;
      padding: 0;
      display: flex;
      border: 1px solid #DFE2EA;
      border-radius: 4px;
      box-shadow: 0 0 16px 0 rgba(0,0,0,0.1);
      background-color: #fff;
      overflow-x: auto;
      max-width: 70%;
      
      >li {
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        font-size: 14px;
        color: #000000;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        font-family: 'Silka-SemiBold', sans-serif;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        border-right: 1px solid #DFE2EA;

        &:last-child {
          border-right: none;
        }

        &:hover {
          background-color: #DFE2EA;
        }
        &.current {
          background-color: #DFE2EA;
          cursor: text;
        }

        &.disabled {
          cursor: text;
          &:hover {
            background-color: #fff;
          }
        }
      }
    } 
  }
