.selectBrandBackground {
    background: radial-gradient(circle, #FFFFFF 0%, #F0F2F5 100%); // margin-bottom: 0;
    // box-sizing: border-box;
    background-size: 200%;
    min-height: 100vh;
    width: 100%;
    margin: 0;
}
.selectAddBrandContainer {
    width: 100%; // flex-direction: column;
    margin: 0 auto;
    min-height: 50vh;
    max-height: 50vh;
    .selectBrandHeader {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        display: flex;
        position: relative;
        .blueButton {
            position: absolute;
            width: 110px;
            font-size: 16px;
            bottom: 12px;
            right: 0;
            border-radius: 4px;
        }
    }
    .divider {
        margin-top: 38px; // margin-bottom: 40px;
        height: 1px;
        width: 100%;
        background-color: #DFE2EA;
    }
    .selectBrandContent {
        .selectBrandTitle {
            height: 16px;
            color: #000000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 16px;
            letter-spacing: -1px;
            line-height: 16px;
            text-align: center;
        }
        .selectBrandContent {
            .selectBrandTitle {
                height: 16px;
                color: #000000;
                font-family: 'Silka-Bold', sans-serif;
                font-size: 16px;
                letter-spacing: -1px;
                line-height: 16px;
                text-align: center;
            }
        }
        .selectBrandSelectionContainer {
            // display: flex;
            flex-direction: column; // background-color: blue;
            // align-items: flex-start;
            width: 100%;
            min-width: 280px;
            margin: 0 auto;
            position: relative;
            .addItemContainer {
                position: absolute;
                top: 64px;
                left: 0; 
                width: 100%;
                height: 70px; // width: 100%;
                border: 1px solid #DFE2EA;
                border-radius: 0 0 4px 4px;
                background-color: #FFFFFF;
                display: flex;
                flex-direction: row;
                align-items: center;
                z-index: 20;
                .plusContainer {
                    margin-left: 29px;
                    box-sizing: border-box;
                    height: 32px;
                    width: 32px;
                    border-radius: 16px;
                    border: 1px solid #FFFFFF;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    .plusIcon {
                        height: 12px;
                        width: 12px;
                    }
                }
                .createLabel {
                    margin-left: 14px;
                    color: #71767D;
                    font-family: "azo-sans-web", sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .brandItemListContainer {
            position: absolute;
            top: 64px;
            left: 0;
            width: 100%;
            height: 220px;
            overflow-y: auto;
            margin: 0 auto;
            z-index: 10;
        }
        .brandItemListContainer + .addItemContainer {
            top: 284px;
        }
        .selectBrandInputContainer {
            width: 100%;
            margin: 0 auto;
            border: 1px solid #DFE2EA;
            border-radius: 4px 4px 0 0;
            background-color: #FFFFFF;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
            display: flex;
            justify-content: flex-start;
            align-items: center; // flex-direction: column;
            .searchIconContainer {
                padding: 0 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .prefixIcon {
                    height: 20px;
                }
            }

            .input-field {
                width: 100%;
                height: 70px;
                >input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    height: 70px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    font-size: 22px!important;
                }
            }
            input {
                width: 100%;
                border: none; // border-bottom: 1px solid #9e9e9e;
                height: 3rem;
                font-size: 16px;
                line-height: 24px;
                margin: 10px 0 8px 0;
                font-family: "azo-sans-web", sans-serif;
                font-weight: 400;
            }
        }
        .additionBottom {
            margin-bottom: 40px;
        }
        .mybrandsContainer {
            margin: 0;
            //padding-top: 60px;
            .mybrandTitleContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                //padding-bottom: 20px;
                .mybrandTitle {
                    color: #0062FF;
                    font-family: 'Silka-Bold', sans-serif;
                    font-size: 16px;
                    letter-spacing: 2px;
                    line-height: 16px;
                    text-align: center;
                }
            }
            .brands-list > div {
                width: 100%;
               // padding: 0 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;                
            }

            .mybrandCardContainer {
                width: 100%;
                height: 81px;
                box-sizing: border-box;
                margin-top: 10px;
                background-color: transparent;
                display: flex;
                justify-content: space-around;
                padding: 0;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
                .brandName {
                    width: 180px;
                    white-space: normal;
                }
            }

            .mybrandTile {
                background-color: blue;
            }
        }
    }
    .selectBrandDescriptionContainer {
        display: flex;
        justify-content: center;
        align-items: center; // width: inherit;
        //margin: 0 45px; // margin-bottom: 40px;
        //margin-top: 12px;
        word-break: break-word; // background-color: red;
    }
    .descWithHighlight {
        color: #000000;
        font-family: "azo-sans-web", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        white-space: pre;
    }
    .selectBrandDescription {
        font-family: "azo-sans-web", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #71767D;
        word-break: break-word;
    }
}

.selectBrandBackFootBox {
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
    height: 100px;
    width: 100%;
    margin: 0 0;
    padding: 0 0; // flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .costContainer {
        display: flex;
        visibility: hidden;
        flex-direction: column; // width: 12.5%;
        // height: 48px;
        // background-color: #0062FF;
        .costLabel {
            color: #71767D;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 14px;
            white-space: nowrap; // text-align: center;
        }
        .costValue {
            margin-top: 6px;
            color: #1BA379;
            font-family: 'azo-sans-web', sans-serif;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: -1px;
            line-height: 42px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .icon {
                width: 20px;
                height: 20px;
                margin-left: 10px; // align-self: center;
            }
        }
    }
    .blueButton.finishedButton {
        width: 12.5%;
        min-width: 100px;
        margin: 0;
    }
}

// }
