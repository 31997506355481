.twoByTwoChart {
    // padding-bottom: 50px;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 250px);
    background-color: #FFFFFF;
    .leftBlock {
        padding-top: 30px;
        // margin-bottom: 18px;
        border-right: 1px solid #DFE2EA;
        min-width: 285px;
        max-height: 100vh;
        overflow-y: scroll;

        .selectContainer {
            display: flex;
            margin-bottom: 30px;

            .mainSelectContainer {
                display: flex;
                height: 100%;
                min-width: 260px;
                align-items: center;
                padding-left: 24px;
            }

            .secondarySelectContainer {
                display: flex;
                flex: 1 1;
                align-items: center;
                height: 100%;
                padding-left: 30px;
                // min-width: 500px;
            }

            .inputBox {
                min-width: 190px;
                height: 62px;
                box-sizing: border-box;
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                padding: 20px 50px 20px 30px;
                margin-right: 20px;
                text-transform: capitalize;

                img.ddArrow {
                    top: 28px;
                }
                .selectListContainer {
                    max-height: 247px;
                    top: 106%;
                    >.selectItem {
                        font-size: 16px;
                        padding: 4px 30px;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .segments {
            padding-left: 32px;

            .buttonContainer {
                margin-top: 40px;
                .titleBlock {
                    display: flex;
                    margin-left: -32px;
                    align-items: center;
                    justify-content: center;
                }
            }

            .iconContainer {
                cursor: pointer;
                height: 60px;
                width: 60px;
                border-radius: 30px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s ease-in-out;
                &:hover {
                    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
                }
            }
        }
    }

    .chartArea {
        position: relative;
        width: 100%;
        min-height: 100%;
        min-width: 1300px;
        overflow: hidden;

        .axisSelect {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            bottom: 0;
            height: 80px;
            width: 100%;
            background-color: #fff;
            border-top: 1px solid #DFE2EA;
            cursor: pointer;

            .textBlock {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: "azo-sans-web", sans-serif;
                text-transform: capitalize;
                font-weight: 500;
                .title {
                    color: #0062FF;
                }
                .subtitle {
                    color: #0062FF;
                    white-space: nowrap;
                }
                .arrowIcon {
                    width: 10px;
                    height: 10px;
                }
            }

            &--selected {
                background-color: #F2F4FA;
            }
        }

        .axisSelectVertical {
            @extend .axisSelect;
            width: 80px;
            height: 100%;
            border-left: none;
            border-right: 1px solid #DFE2EA;

            .textBlock {
                transform: rotate(-90deg);
            }
        }

        .axisSelectRight {
            @extend .axisSelectVertical;
            border-left: 1px solid #DFE2EA;
            border-right: none;
            right: 0;
        }

        .chartContainer {
            left: 80px;
            bottom: 80px;
            width: calc(100% - 160px);
            height: calc(100% - 80px);
            position: absolute;
            width: 100%;
            height: 100%;
            // min-width: 1440px;

            .xAxis, .yAxis {
                position: absolute;
                display: flex;
                align-items: center;

                .axisLine {
                    background-color: #DFE2EA;
                }

                .valueContainer {
                    min-width: 25px;
                    color: #B5B9BF;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 13px;
                    letter-spacing: 0;
                    // text-align: right;
                }
            }

            .xAxis {
                width: 100%;

                .axisLine {
                    height: 1px;
                    width: 100%;
                    // width: calc(100% - 160px);
                }

                .valueContainer {
                    margin-right: 10px;
                    text-align: center;
                }

                &:first-child, &:last-child {
                    .axisLine {
                        visibility: hidden;
                    }
                }
            }

            .yAxis {
                flex-direction: column;
                height: 100%;

                .axisLine {
                    width: 1px;
                    height: 100%;
                }

                &:last-child {
                    .axisLine {
                        visibility: hidden;
                    }
                }

                .valueContainer {
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }

        .filter-dropdown {
            z-index: 100;
        }
    }

    .diagramTooltip {
        display: none;
        position: absolute;
        z-index: 50;
        bottom: calc(100% + 10px);
        padding: 20px 10px;
        box-sizing: border-box;
        width: auto;
        min-width: 200px;
        border: 1px solid #DFE2EA;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23,24,26,0.1);
        color: #000000;
        min-height: 200px;
        .diagramTooltipTitle {
            padding-bottom: 10px;
            font-family: 'Silka-SemiBold', sans-serif;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            border-bottom: 1px solid #DFE2EA;
            .absoluteValue {
                font-weight: 500;
                height: 25px;
                color: #71767D;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 30px;
                text-align: center;
            }
            span {
                display: block;
                font-size: 14px;
                margin-top: 5px;
            }
        }
        .valuesBlock {
            margin-top: 15px;
            font-family: "azo-sans-web", sans-serif;
            text-align: center;

            .row {
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                height: 30px;
                color: #71767D;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 30px;
                text-align: center;
                .axis {
                    white-space: nowrap;
                    margin-right: 10px;
                }
                .value {
                    font-size: 16px;
                    color: #000;
                }
            }
        }
    }

    .map {
        width: calc(100% - 220px);
        height: calc(100% - 130px);
        position: relative;
        margin-top: 120px;
        margin-left: 15px;
        z-index: 0;
        .chartItem{
            position: absolute;
            border-radius: 50%;
            background-color: #F1F2F7;
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
            color: #FFFFFF;
            font-family: 'azo-sans-web', sans-serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            white-space: nowrap;
            // padding: 10px 25px;
            // z-index: 1;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            // &:hover {
            //     opacity: 0.6;
            // }
        }
        .chartItem:hover + .diagramTooltip{
            display: block;
        }

        .chartItem:hover {
            opacity: 1 !important;
            z-index: 100 !important;
        }
    }

    .chartItemContainer {
        position: absolute;
    }
}

.brandLogo {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 15px;

    &.textLogo {
      box-sizing: border-box;
      background-color: #292D33;
      color: #DFE2EA;
      font-family: 'Silka-Bold', sans-serif;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
}
