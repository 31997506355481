.dropDown {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }

  .marketLogo{
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 15px;
  }

  .dropDownHeaderContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    img.ddArrow {
      transition: all 0.5s;
      // margin-right: 10px;
    }

    .multiSelected {
      display: flex;
      .selectedNum {
        box-sizing: border-box;
        height: 42px;
        width: 42px;
        border: 2px solid #EFF1F6;
        border-radius: 50%;
        font-family: 'Silka-SemiBold', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selectedNum:hover .tooltiptext {
        visibility: visible;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .selectedNum .tooltiptext {
        visibility: hidden;
        border-radius: 6px;
        padding: 5px 9px;
        position: absolute;
        z-index: 1;
        left: 15%;
        top: 100%;
        font-size: 13px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
        color: #71767D;
      }

      .selectedListConatiner {
        height: 45px;
        min-width: 150px;
        display: flex;
        position: relative;
        .brandItem {
          position: absolute;
          padding: 0;
          .brandTitle {
            .brandName {
              display: none
            }
          }
        }
        .marketItem {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .marketTitle{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            position: absolute;
            .marketLogo{
              height: 40px;
              width: 40px;
              object-fit: cover;
              border-radius: 25px;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
              margin-right: 10px;
            }
          }
        }
      }
    }

    .campaignTitle {
      display: flex;
      height: 39px;
      flex-direction: column;
      justify-content: space-between;

      .campaignRegion {
        font-family: "Silka-SemiBold",sans-serif;
        font-size: 15px;
        height: 22px;
        line-height: 21.78px;
        color: #000000;
      }

      .campaignName {
        font-size: 12px;
        font-weight: 500;
        color: #0062FF;
        line-height: 19.94px;
        white-space: nowrap;
      }
    }

    .brandTitle {
      .brandLogoIcon, .brandDefaultLogo {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 25px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        border: '2px solid #fff';
        @media screen and (max-width: 812px) {
          height: 40px;
          width: 40px;
          border-radius: 20px;

        }
      }
      
      .brandLogoIcon {
        background-color: #ffffff;
      }

      .brandName {
        font-weight: 400;
        width: 100%;
        font-size: 15px;
        color: #000000;
        font-family: "Silka-SemiBold", sans-serif;
        overflow: hidden;
        line-height: 21.87px;
        text-overflow: ellipsis;
        // display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;

        @media screen and (max-width: 812px) {
          width: calc(100% - 50px);
          font-size: 18px;
          line-height: 22px;
          white-space: normal !important;
        }
      }

      .brandName:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
        text-overflow: clip;
        word-break: break-all;
        white-space: nowrap;
      }
    
      .brandName .tooltiptext {
        visibility: hidden;
        min-width: 100px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 5px 9px;
        height: 25px;
        position: absolute;
        z-index: 1;
        left: 30%;
        top: 80%;
        font-size: 12px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        padding-top: 5px;
        white-space: nowrap;
        border-radius: 15px;
      }
    }
  }

  .dropDownContainer{
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 380px;
    border: 1px solid #DFE2EA;
    background-color: #ffffff;
    z-index: 20;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    .selectedBrands{
      padding: 10px 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
    }
  }

  .dropdownListContainer{
      display: none;
      box-sizing: border-box;
      border: 1px solid #DFE2EA;
      background-color: #ffffff;
      border-radius: 4px;
      min-height: 25px;
      max-height:280px;
      overflow-y: auto;
      overflow-x: hidden;

      >.selectItem {
        width: 100%;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

      &.selected {
        color: #0062FF;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: #F2F4F7;
      }

      .icon {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 15px;
        margin-right: 15px;
        &.textIcon {
          box-sizing: border-box;
          border: 2px solid #FFFFFF;
          background-color: #292D33;
          color: #DFE2EA;
          font-family: 'Silka-Bold', sans-serif;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .name {
        width: calc( 100% - 45px );
        >.sSize {
          width: auto;
          font-family: 'azo-sans-web', sans-serif;
          font-weight: 400;
          font-size: 12px;
          color: #71767D;
        }
      }
    }
    .selectCampaignContainer {
      display: flex;
      border-bottom: 1px solid #DFE2EA;
      background-color: #DFE2EA;
  
      &:last-of-type {
        border: none;
      }
  
      .campaignNameBox {
        width: 50%;
        padding: 10px;
        background-color: #F2F3F7;
        border-right: 1px solid #DFE2EA;
        font-size: 14px;
        font-weight: 600;
        cursor: default;
      }
  
      .campaignRegionBox {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px;
        background-color: #FFFFFF;
        font-size: 14px;
        color: #4B4D53;
        .campaignRegion {
          word-wrap: break-word;
  
          &:hover {
            color: #000000;
          }
        }
  
        .selected {
          color: #0062FF;
        }
      }
    }
  }

  &.open {
      img.ddArrow {
          transform: rotate(-180deg);
      }
      .dropdownListContainer {
          display: block;
      }
      .dropDownContainer {
        display: block;
    }
  }

  input[type="checkbox"] {
    height: 30px;  
    width: 20px;                      
    clip-path: circle(56% at 50% 50%);  
    margin-right: 10px; 
    accent-color:#1BA379;
  }
}
