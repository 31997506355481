@keyframes react-responsive-modal-from-right-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes react-responsive-modal-from-right-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.views_detail_modal {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  height: 100vh;
  min-width: 640px;
  overflow: hidden;
}

.views_detail_modal_root {
    .react-responsive-modal-overlay {
        background-color: #00000028;
    }
}
