.StatusLabel {
    color: #1BA379;
    font-family: "azo-sans-web", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 29px;
    &.active, &.verified {
        color: #a406f5; 
        background-color: rgba(235, 16, 231, 0.1);
    }
    &.inactive {
        color: #E51E00;
        background-color: rgba(229,30,0,0.1);
    }
    &.expired, &.notVerified {
        color: #71767D;
        background-color: #DFE2EA;
    }
    &.future {
        color: #5500FF;
        background-color: rgba(85,0,255,0.1);
    }
}
