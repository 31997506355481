.brandItemListContainer {
    // margin: 0 30px;
    // background-color: red;
    // width: 100%;
    border-radius: 0 0 4px 4px;
    background-color: #FFFFFF;
    border: 1px solid #DFE2EA;
    // margin-block-start: 0;
    // margin-block-end: 0;
    // padding-inline-start: 0;
    padding: 0 0;
    // opacity: 0.5;
}