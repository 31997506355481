.periodPickerContainer {
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2px;
    box-sizing: border-box;
    gap: 8px;
    .preLabel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-height: unset;
        text-align: right;
        color: #081D58;
        font-family: 'azo-sans-web', sans-serif;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 16px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        margin-right: 12px;
        &.post {
            color: #41B6C4;
            padding-left: 20px;
        }
        .sampleSize {
            width: auto;
            font-family: 'azo-sans-web', sans-serif;
            font-weight: 400;
            font-size: 11px;
            color: #71767D;
            text-transform: none;
        }
    }
    >div {
        min-height: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        >.periodIcon {
            padding-right: 5px;
        }
        >.inputBox {
            width: auto;
            gap: 5px;
            padding: 0px;
            align-items: center;
            height: 40px;
            &.periodOptions{
                margin-right: 5px;
            }
            
            &.interval {
                width: auto;
            }

            img.ddArrow {
                top: 20px;
            }
            .selectListContainer {
                top: calc( 100% + 5px );
                max-height: 220px;
            }
        }
        &.customInputDate {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            min-height: 60px;
            box-sizing: border-box;
            padding: 5px 0;
            font-size: 14px;
            // font-family: "Inter", sans-serif; 
            >.dateInput {
                min-width: 80px !important;
                padding: 10px 0;
                width: 115px;
                margin-right: 0px;
                .react-date-picker__calendar {
                    top: 100% !important;
                    bottom: unset !important;
                }
                &.inputBox:nth-child(2){
                    margin-left: 32px;
                    &::before {
                        content: " to ";
                        position: absolute;
                        left: -28px;
                    }
                }
            }
        }
    }
}
