.filter-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    height: 132px;
    min-width: 277px;
    border: 1px solid #DFE2EA;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23,24,26,0.1);
    cursor: initial;

    .titleContainer {
        display: flex;
        justify-content: space-between;

        .filter-dropdown-title {
            margin-bottom: 16px;
            color: #000000;
            font-family: "Silka-Bold", sans-serif;
            font-size: 14px;
            line-height: 18px;
        }
        .filter-dropdown_disable-option {
            color: #0062FF;
            font-family: "azo-sans-web", sans-serif;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .selectContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .inputBox {
            &.twobytwo-axis-select {
                display: flex;
                margin-right: 10px;
                justify-content: flex-start;
                align-items: center;
                min-width: 200px;
                height: 50px;
                text-transform: capitalize;
                border: 1px solid #DFE2EA;
                border-radius: 3px;
                img.ddArrow {
                    position: static;
                    margin-top: 8px;
                    margin-left: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &--x-axis {
        left: calc(50% - 190px);
        top: -178px;
    }

    &--y-axis {
        left: 70px;
        top: calc(50% - 76px);
    }

    &--z-axis {
        right: 70px;
        top: calc(50% - 76px);
    }
}
