body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  line-height: 1.5;
  font-weight: normal;
  color: rgba(0,0,0,0.87);
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Silkaregular';
  src: url('./fonts/silka-regular-webfont.eot');
  src: url('./fonts/silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/silka-regular-webfont.woff2') format('woff2'),
       url('./fonts/silka-regular-webfont.woff') format('woff'),
       url('./fonts/silka-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Silka-Bold';
  src: url('./fonts/Silka-Bold.woff');
  src: url('./fonts/Silka-Bold.woff2') format('woff2'),
       url('./fonts/Silka-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Silka-Medium';
  src: url('./fonts/Silka-Medium.woff');
  src: url('./fonts/Silka-Medium.woff2') format('woff2'),
       url('./fonts/Silka-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Silka-SemiBold';
  src: url('./fonts/Silka-SemiBold.woff');
  src: url('./fonts/Silka-SemiBold.woff2') format('woff2'),
       url('./fonts/Silka-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'silkaregular_italic';
  src: url('./fonts/silka-regularitalic-webfont.eot');
  src: url('./fonts/silka-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/silka-regularitalic-webfont.woff2') format('woff2'),
       url('./fonts/silka-regularitalic-webfont.woff') format('woff'),
       url('./fonts/silka-regularitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}