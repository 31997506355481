.modalLayer > div.brandContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    .modalTitle {
        padding: 32px 0;
        margin: 0;
        color: #000000;
        font-family: 'Silka-Bold', sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 32px;
        border-bottom: 1px solid #DFE2EA;
    }
    .modalDescription {
        max-width: 500px;
        margin-top: 32px;
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
    }
    .brandTitle {
        width: 100%;
        margin: 0 auto;
        padding: 24px 0 0;
        flex-direction: column;
        justify-content: flex-end;
        .brandDefaultLogo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 0;
            >div {
                font-size: 60px;
                line-height: 66px;
            }
        }
        .brandLogoIcon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: 0;
        }
        .brandName {
            height: 22px;
            width: 100%;
            margin: 24px auto 0;
            color: #000000;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
            padding: 0;
            font-family: "azo-sans-web", sans-serif;
            font-size: 18px;
            letter-spacing: 0;
        }
    }
    .brandEdit {
        margin: 40px auto;
        text-align: center;
        >input {
            box-sizing: border-box;	
            height: 60px;	
            width: 100%;
            border: 1px solid #DFE2EA!important;	
            border-radius: 4px;
            color: #000000;	
            font-family: "azo-sans-web", sans-serif;	
            font-size: 16px;
            font-weight: 400;
            padding: 20px;
        }
        .brandLogoIcon {
            box-sizing: border-box;
            display: flex;
            margin: 10px auto;
            height: 128px;
            width: 128px;
            border-radius: 90px;
            align-self: center;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-size: 100%;
            border: 1px solid #292D33;
            &.uploadImg {
                background-color: #292D33;
                color: #DFE2EA;
                font-family: 'Silka-Bold', sans-serif;
                font-size: 50px;
                line-height: 50px;
                text-align: center;
            }
          }
    }
    .addedBy {
        margin: 0 auto;
        color: #000000;
        font-family: "azo-sans-web", sans-serif;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0;
        letter-spacing: 0;
    }
    .bottomButtons {
        flex-wrap: wrap;
        padding: 32px 0;
        .textButton {
            padding: 0;
            padding-top: 32px;
            width: 100%;
            color: #E51E00;
            text-align: center;
            font-family: 'Silka-SemiBold', sans-serif;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            text-transform: initial;
            &:hover {
                background: none;
                box-shadow: none;
            }
        }
    }
    
}
