.tabsContainer {
    height: 80px;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    margin-left: 30px;
    @media screen and (max-width: 812px) {
        width: 100vw;
    }
    .tabs-list {
        min-width: 1000px;
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        @media screen and (max-width: 812px) {
            min-width: 812px;
        }
        .highlightBar {
            display: block;
            width: 0;
            height: 4px;
            background-color: #7F56D9;
            position: absolute;
            bottom: -1px;
            left: 0;
            transition: .3s ease-in-out;
        }

        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #71767D;
            font-family: "Silka-SemiBold", sans-serif;
            font-size: 12px;
            line-height: 22px;
            text-transform: none;
            height: 100%;
            padding: 0 20px 6px;
            cursor: pointer;
            &.current{
                color: #7F56D9;
            }
            &:hover {
                color: #000000;

            }
            & a {
                text-decoration: none;
                color: inherit;
                height: 30px;
            }
            .MuiBreadcrumbs-separator {
                margin: 0px 3px;
            }

            .active{
                color: #7F56D9;
                border-bottom: 4px solid #7f56d9;
                padding: 10px 0px;
            }

            @media screen and (max-width: 812px) {
                width: 160px;
            }
            .brandTitle {
                .brandLogoIcon, .brandDefaultLogo {
                    height: 40px;
                    width: 40px;
                    object-fit: cover;
                    border-radius: 20px;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
                    margin-right: 12px;
                }
                .brandName {
                    width: calc( 100% - 52px);
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    font-family: "Silka-SemiBold", sans-serif;
                }
            }
        }
        .tab:first-child{
            padding-left: 0px;
        }

    }

    .betaBlock {
        font-size: 14px;
        display: flex;
        flex: 1 1;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        min-width: 285px;
        padding-right: 50px;
        margin-left: 70px;
        .betaBtn {
            height: 32px;
            width: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            background-color: #0062FF;
            color: white;
            font-family: "Silka-SemiBold", sans-serif;
            margin-right: 12px;
        }
        .betaTxt {
            font-family: "azo-sans-web", sans-serif;
            color: #71767D;
            span {
                color: black;
            }
        }
    }
}
.tabsinMenu {
    width: 160px;
    box-sizing: border-box;
    padding: 0;
    margin: -10px 0 -20px 65px;
    .tabs-list {
        list-style: none;
        .tab {
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-transform: none;
            margin: 10px 0;
            cursor: pointer;
            &.current {
                color: #000000;
            }
           
        }
    }
}
    .search-input{
        display: flex;
        padding: 10px 0;
        border: 1px solid #dfe2ea;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
        position: relative;
    }
    .search-icon{
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .prefixIcon {
            width: 1rem;
            height: 1rem;
        }
        .postfixIcon {
            width: 1.5rem;
            height: 1.5rem;
        }
}
