.profileForm {
    width: 663px;
    margin: 32px 20px;
    .textInputBlock{
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 50px;
    }
    .zeroImgContainer {
        margin-top: 41px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
    }
    .zeroImg {
        width: 560px;
        height: 454px;
    }

    .zeroTitle {
        color: #000000;
        font-family: 'Silka-Bold', sans-serif;
        font-size: 24px;
        letter-spacing: -0.5px;
        line-height: 32px;
        text-align: center;
    }

    .zeroMessage {
        display: flex;
        justify-content: center;
        span {
            width: 500px;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
        }
    }
    
    .menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        z-index: 999;
        width: 189px;
        border: 1px solid #DFE2EA;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 #dfe2ea;
        background-color: #FFF;
        box-sizing: border-box;
        margin-top: 5px;
    }
    .menuItem{
            height: 32px;
            color: #71767D;
            font-family: "azo-sans-web";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 32px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 3px;
            &:hover {
                background-color: #F2F4F7;
                color: #0062FF;
                cursor: pointer;
                width: 92%
            }

            &.separate {
                color: #E51E00;

                &:hover {
                    color: #E51E00;
                }
            }
    }
    .eyes {
        position: absolute;
        right: 10px;
        top: 18px;
    }

    .formFont {
        color: #000000;
        font-family: "azo-sans-web", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
    }

    .profileFont {
        color: #000000;
        font-family: "azo-sans-web";
        font-weight: 500;
        font-size: 24px;
    }

    .inputMessage {
        margin: 16px 0;
        padding-left: 263px;
        color: #71767D;
        font-family: "azo-sans-web", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .errorMessage {
        color: red;
        text-align: center;
    }

    .inputBlock {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0 20px;

        .datesContainer {
            display: flex;
            justify-content: space-between;
            width: 400px;

            .formInput {
                position: relative;
                .inputBox.dateInput.contractDateInput {
                    height: 60px;
                    width: 192px;
                    line-height: 30px;
                    margin: 0;
                    padding: 0;

                    &--disabled {
                        border: none;
                    }

                    .react-date-picker.dateInput_picker {
                        width: 100%;
                        .react-date-picker__wrapper {
                            .react-date-picker__inputGroup {
                                margin-left: 48px;
                                font-size: 16px;
                            }
                        }
                        input, select {
                            height: 100%;
                            font-family: "azo-sans-web", sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            color: #000000;
                            padding: 0;
                            line-height: 18px;
                            &--disabled {
                                border: none;
                            }
                        }
                    }

                    .react-date-picker.react-date-picker--disabled {
                        background-color: #F3F4F7;
                    }
                }
                .calendarIcon {
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .inputBox.formInput {
            width: 400px;
            height: 60px;
            padding: 0;
            margin: 0;
            border: none;
            cursor: initial;

            input, .surveyDropdownInput {
                @extend .formFont;
                height: 60px;
                border: 1px solid #DFE2EA!important;

                &:disabled {
                    background-color: #F3F4F7;
                    border: 1px solid transparent!important;
                }
            }
            &--heightAuto {
                height: auto;
            }

            &.formError {
                border: 1px solid red!important;
            }
        }

        .surveyDropdown {
            width: 100%;
            cursor: pointer;

            .surveyDropdownInput {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img.ddArrow {
                    transition: all 0.5s;
                    position: inherit;
                }
            }

            &.open {
                img.ddArrow {
                    transform: rotate(-180deg);
                }
            }

            &.disabled {
                img.ddArrow {
                    display: none;
                }

                cursor: default;
                background-color: #F3F4F7;

                .surveyDropdownInput {
                    border: 1px solid transparent !important;
                }
            }

            .surveyDropdownItems {
                position: absolute;
                width: 100%;
                z-index: 1;
                background-color: #f5f7fa;

                .surveyDropdownItem {
                    border: 1px solid #DFE2EA;
                    border-top: unset;
                    padding: 0 20px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    font-family: "azo-sans-web", sans-serif;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 21px;
                    height: 48px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 16px;

                    .subPath{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        font-size: 14px;
                        color: #71767D;
                    }

                    &:hover {
                        background-color: #DFE2EA;
                    }
                }
            }
        }



        .inputBox.formSelect {
            width: 400px;
            padding: 20px;
            margin: 0;
            padding: 20px;
            font-weight: 400;
            border: 1px solid #DFE2EA;
            justify-content: space-between;
            @extend .formFont;

            &--disabled {
                border: none;
            }

            .ddArrow {
                top: 27px;
            }
        }

        .inputLabel {
            color: #000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            padding-top: 20px;

            .requiredInd {
                color: #E51E00;
            }
        }
    }

    .geographyBlock {
        align-items: flex-start;

        .geographyLabel {
            margin-top: 20px;
        }

        .geographyList {
            .geographyItem {
                box-sizing: border-box;
                height: 80px;
                width: 400px;
                margin-bottom: 16px;
                padding: 21px;
                display: flex;
                justify-content: center;
                border: 1px solid #DFE2EA;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
                flex-direction: column;
                cursor: pointer;

                label {
                    cursor: pointer;
                }

                .geographyName {
                    font-weight: 600;
                    font-size: 16px;
                }

                .geographyRegion {
                    font-size: 14px;
                    color: #0062FF;
                }
            }
        }
    }

    .brandBlock {
        align-items: flex-start;

        .brandLabel {
            margin-top: 20px;
        }

        .brandList {
            .brandData{
                justify-content: flex-start !important;
                border: 1px solid rgb(223, 226, 234)
            }
            .brandItem {
                box-sizing: border-box;
                height: 75px;
                width: 400px;
                margin-bottom: 16px;
                padding: 21px;
                display: flex;
                justify-content: space-between;
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 15px 12px !important;

                &:hover {
                    cursor: pointer;
                    .brandStyle {
                        text-decoration: underline;
                    }
                }

                .brandGroupDropdown {
                    top: 40px;
                    right: 30px;
                }

                .logoContainer {
                    line-height: 0;
                    box-sizing: border-box;
                    height: 40px;
                    width: 40px;
                    border: 1.6px solid #FFFFFF;
                    border-radius: 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .logo {
                        width: 40px;
                        height: 40px;
                        border-radius: 20px;
                    }
                }

                .brandDefault.brandLogoDefault {
                    height: 40px;
                    width: 40px;
                    border: 1.6px solid #FFFFFF;
                    border-radius: 21px;
                    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
                    font-size: 18px;
                }

                .titleContainer {
                    margin-left: 16px;
                    display: block;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    max-width: 75%;

                    &.contactForm {
                        max-width: 80%;
                    }

                    .tooltipBlock {
                        // position: relative;
                        // position: relative;
                        display: inline-block;
                        white-space: nowrap; 
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      
                      .tooltipBlock .tooltiptext {
                        visibility: hidden;
                        min-width: 100px;
                        background-color: #DFE2EA;
                        color: black;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px 8px;
                        height: 25px;
                        position: absolute;
                        left: 0;
                        z-index: 1;
                        padding-top: 10px;
                        margin-top: -50px;
                        font-weight: 400;
                      }
                      
                      .tooltipBlock:hover .tooltiptext {
                        visibility: visible;
                        cursor: pointer;
                      }

                    .brandName {
                        @extend .profileFont;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    .primaryBrand {
                        color: #0062FF;
                        font-family: "azo-sans-web", sans-serif;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                }

                .hoverIcon {
                    display: block;
                    visibility: hidden;
                    margin: auto 30px auto 0;
                    &.primarySetting {
                        position: absolute;
                        right: 20px;
                        margin-right: 0px;
                    }
                }
                &:hover .hoverIcon {
                    visibility: visible;
                    cursor: pointer;
                }
            }

            .brandbox{
                width: 300px;
                margin-left: 90px;
                .brandNameBox{
                    max-width:75% !important;
                }
            }

            .quotaItem{
                width:78px;
                justify-content:center;
                cursor:context-menu !important;
                .quotaTextBox{
                    margin-left:3px;
                    text-decoration: none !important;
                }
            }
        }
    }


    .vendorQuotaBlock{
        border-top: 1px solid #DFE2EA;
        border-bottom: 1px solid #DFE2EA;
        padding-top: 20px;
        margin-bottom: 20px;
    }

    .vendorQuotaVendorName {
        color: #71767D;
        font-family: 'azo-sans-web', sans-serif;
        font-size: 16px;
    }

    .uploadBrandLogoBlock {
        align-items: flex-start;

        .uploadBrandLogoLabel {
            margin-top: 20px;
        }

        .uploadLogoContainer {
            position: relative;

            .uploadBrandLogoImage {
                width: 250px;
                height: 250px;
                background-position: center;
                position: absolute;
                z-index: 10;
                top: 75px;
                left: 75px;
                border: 1px solid transparent;
                border-radius: 125px;
            }
                .showOrHideIconContainer {
                    padding-bottom: 15px;
                    .showOrHideIcon {
                        display: none;
                    }
                }
                &:hover {
                    .showOrHideIconContainer {
                            .showOrHideIcon {
                                display: flex;
                                width: 1.5rem;
                                height: 1rem;
                                padding: 0px 2px;
                                float: right;
                            }
                            &.rightRem {
                                right: 1.5rem;
                            }
                        } 
                    }
            // }

            .addBrandLogoBlock {
                max-width: 250px;
                position: absolute;
                z-index: 10;
                top: 133px;
                left: 75px;
                cursor: pointer;
            }
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        margin: 32px 0;
        background-color: #DFE2EA;
    }

    .selectInputContainer {
        width: 400px;
        height: 60px;
        box-sizing: border-box;
        border: 1px solid #DFE2EA;
        border-radius: 4px 4px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .searchIconContainer {
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .prefixIcon {
                height: 20px;
            }
        }

        input {
            width: 100%;
            @extend .formFont;
        }
    }

    .disabledInputContainer{
        width: 400px;
        height: 60px;
        box-sizing: border-box;
        border: 1px solid #DFE2EA;
        border-radius: 4px 4px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #F3F4F7;
        border: 1px solid transparent!important;
        .searchIconContainer {
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .prefixIcon {
                height: 20px;
            }
        }
    }

    .selectInputQuotaContainer {
        box-sizing: border-box;
        height: 75px;
        width: 150px;
        border: 1px solid #DFE2EA;
        border-radius: 4px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
        margin-left: 16px;
        margin-right: 16px;
        input {
            //width: 100%;
            height: 18px;
            width: 29px;
            color: #000000;
            font-family: "azo-sans-web";
            font-weight: 400;
            font-size: 16px;
            margin-left: 20px;
            letter-spacing: 0;
            line-height: 18px;
            //@extend .formFont;
        }
    }

    .formSuggestionsContainer {
        top: 60px;
        right: 20px;
        left: initial;
    }

    .selectedList {
        margin-left: 250px;

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 380px;
            margin-bottom: 24px;

            .titleBlock {
                display: flex;
                flex-direction: column;

                .title {
                    @extend .formFont;
                }

                .itemId {
                    @extend .formFont;
                    color: #71767D;
                    font-size: 14px;
                }
            }

            .removeIcon {
                display: none;
                cursor: pointer;
            }

            &:hover .removeIcon {
                display: block;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .campaignItem {
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 0;

            .titleBlock {
                display: flex;
                flex-direction: column;

                .campaignName {
                    font-size: 14px;
                    font-weight: 500;
                }

                .campaignRegion {
                    font-size: 14px;
                    color: #0062FF;
                }
            }

            .removeIcon {
                display: none;
                position: absolute;
                cursor: pointer;
                top: 22px;
                right: 40px;
            }

            &:hover .removeIcon {
                display: block;
            }
        }

        .brandItem {
            height: 64px;
            display: flex;
            margin-right: 8px;
            justify-content: flex-start;
            align-items: center;
            padding: 0;

            .brandLogo {
                width: 40px;
                height: 40px;
                border: 1px solid transparent;
                border-radius: 20px;
            }

            .brandDefaultLogo {
                width: 40px;
                height: 40px;
                border-radius: 21px;
                border: 2px solid #FFFFFF;
                background-color: #292D33;
                color: #DFE2EA;
                font-family: 'Silka-Bold', sans-serif;
                font-size: 18px;
                line-height: 30px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .titleBlock {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 16px;
                letter-spacing: 0;
                line-height: 18px;

                .title {
                    color: #000000;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 16px;
                }

                .subtitle {
                    color: #71767D;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 14px;
                }
            }

            .removeIcon {
                display: none;
                position: absolute;
                cursor: pointer;
                top: 22px;
                right: 40px;
            }

            &:hover .removeIcon {
                display: block;
            }
            .alertIcon {
                width: 25px;
                height: 25px;
                aspect-ratio: auto;

                position: absolute;
                top: 20px;
                right: 60px;
            }
        }
    }

    .buttonsBlock {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        padding: 0 20px;

        .button.addFormButton {
            padding: 16px 20px!important;
            margin: 0;
            margin-left: 16px;

            &--disabled {
                opacity: 0.5;
            }
        }
    }

    .error {
        font-family: "azo-sans-web", sans-serif;
        float: right;
        padding: 16px 20px 0 0;
        font-size: 14px;
        line-height: 16px;
        color: #E51E00;
    }

    .errorWrapper {
        padding: 0 20px;
    }

    .inputBlockWeighting{
        margin-bottom: 16px;
    }
    .childValueLabel{
        height: 16px;
        width: 72px;
        color: #71767D;
        font-family: 'azo-sans-web', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .detailsLabel {
        height: 14px;
        width: 70px;
        color: #0062FF;
        font-family: 'Silka-Bold';
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 14px;
        margin-bottom: 32px;
        padding: 0 20px;
      }
    .addButton{
        box-sizing: border-box;
        margin-bottom: 24px;
        height: 50px;
        width: 188px;
        border: 1px solid #0062FF;
        border-radius: 4px;
        background-color: "#000";
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &--disabled {
            opacity: 0.5;
        }
    }
    .columnTitle{
        height: 50px;
        width: 85%;
        margin-bottom: 8px;
        margin-top: 16px;
        margin-left: 16px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            .brandClearIcon {
                display: flex;
                width: 16px;
                height: 16px;
            }
        }
    }
    .columnTitleText{
        font-family: 'azo-sans-web';
        font-weight: 500;
        font-size: 14px;
    }
    .addButtonLabel{
        height: 18px;
        width: 83.56px;
        color: #0062FF;
        font-family: 'Silka-Bold';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
    }
    .brandClearIcon {
        display: none;
        border-radius: 11px;
    }
    .brandItem{
        &:hover .brandClearIcon {
            display: block;
            width: 17px;
        }
    }
    .contractBrand{
        width: 250px !important;
    }
    .brandNameTooltip{
        width: 130px;
    }
    .decipherName{
        width: 100%;
        height: 35px;
    }
    .decipherInput{
        position: relative; 
        border: 1px solid #DFE2EA; 
        border-radius : 0px;
        box-shadow: none;
        align-items: center;
        width: 400px;
    }
    .segmentWarning {
        margin-left: 6px;
        font-size: 14px;
        font-family: "azo-sans-web", sans-serif;
    }

    .segmentWarning:hover .tooltipWarning {
        visibility: visible;
        text-overflow: clip;
        white-space: normal;
    }

    .segmentWarning .tooltipWarning {
        visibility: hidden;
        min-width: 100px;
        background-color: #dfe2ea;
        color: #000;
        border-radius: 6px;
        height: 45px;
        position: absolute;
        z-index: 1;
        font-size: 13px;
        font-family: "azo-sans-web", sans-serif;
        width: 100%;
        padding: 5px 9px;
        top: -61%;
        line-height: 1.4;
    }
}
