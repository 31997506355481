    .creatBandContainer {
      width: 100%;
      max-width: 960px;;
      margin:0 auto;
      padding: 60px 0 100px;
      .header {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        display: flex;

        .title {
          margin-top: 38px;
          color: #000000;
          font-family: 'Silka-Bold', sans-serif;
          font-size: 32px;
          letter-spacing: -1px;
          line-height: 34px;
          text-align: center;
        }

        .divider {
          height: 1px;
          width: 100%;
          margin-top: 38px;
          background-color: #DFE2EA;
        }
      }

      .content {
        width: 85%;
        margin: 0 auto;
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
        @media screen and (max-width: 812px) {
           flex-direction: column;
           >div {
             margin: 15px auto;
           }
        }

        .contentText {
          width: 100%;
          margin: 20px 0 70px;
          color: #71767D;
          font-family: "azo-sans-web", sans-serif;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          font-weight: 400;
        }

        .inputContainer {
          width: calc(90% - 275px );
          @media screen and (max-width: 812px) {
            width: calc( 100% - 40px );
          }
          .inputBox {
            background-color: transparent;
            width: 100%;
            height: 70px;
            padding: 20px;
            box-sizing: border-box;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 22px!important;
            border: 1px solid #DFE2EA;
            border-radius: 4px;
            margin-bottom: 20px;
            position: relative;
            &.disabled {
              opacity: 0.5;
            }
            input {
              position: absolute;
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
            .placeholder {
              padding: 0;
              color: #B5B9BF;
              font-family: "azo-sans-web", sans-serif;
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
            }
            input:not([type]),
              input[type=text]:not(.browser-default),
              input[type=password]:not(.browser-default),
              input[type=email]:not(.browser-default),
              input[type=url]:not(.browser-default),
              input[type=time]:not(.browser-default),
              input[type=date]:not(.browser-default),
              input[type=datetime]:not(.browser-default),
              input[type=datetime-local]:not(.browser-default),
              input[type=tel]:not(.browser-default),
              input[type=number]:not(.browser-default),
              input[type=search]:not(.browser-default),
              textarea.materialize-textarea {
                  background-color: transparent;
                  border: none; // border-bottom: 1px solid #9e9e9e;
                  border-radius: 0;
                  outline: none;
                  height: 3rem;
                  width: 100%;
                  font-size: 22px;
                  line-height: 24px;
                  color: #000000;
                  margin: -10px 0 8px 0;
                  padding: 0;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                  -webkit-box-sizing: content-box;
                  box-sizing: content-box;
                  -webkit-transition: border .3s, -webkit-box-shadow .3s;
                  transition: border .3s, -webkit-box-shadow .3s;
                  transition: box-shadow .3s, border .3s;
                  transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
                  font-family: "azo-sans-web", sans-serif;
                  font-weight: 400;
              }
              input:not([type]):focus:not([readonly])+label,
              input[type=text]:not(.browser-default):focus:not([readonly])+label,
              input[type=password]:not(.browser-default):focus:not([readonly])+label,
              input[type=email]:not(.browser-default):focus:not([readonly])+label,
              input[type=url]:not(.browser-default):focus:not([readonly])+label,
              input[type=time]:not(.browser-default):focus:not([readonly])+label,
              input[type=date]:not(.browser-default):focus:not([readonly])+label,
              input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
              input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
              input[type=tel]:not(.browser-default):focus:not([readonly])+label,
              input[type=number]:not(.browser-default):focus:not([readonly])+label,
              input[type=search]:not(.browser-default):focus:not([readonly])+label,
              textarea.materialize-textarea:focus:not([readonly])+label {
                  color: #B5B9BF; //   background-color: red;
              }
            }
        }
        .logoUploader {
          width: 275px;
          text-align: center;
          .uploadLabel {
            color: #000000;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 16px;
            text-align: center;
            margin: 0 auto;
          }
          .optionalLabel {
            margin: 10px auto 20px;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }
          .uploadContainer {
            display: flex;
            margin: 10px auto 50px;
            height: 180px;
            width: 180px;
            border-radius: 90px;
            background-color: #292D33;
            align-self: center;
            align-items: center;
            justify-content: center;
            .uploadImage {
              position: absolute;
            }
          }
        }
        .loginWarning {
          font-family: 'Open Sans', sans-serif;
          font-size: 17px;
          margin-top: 15px;
          line-height: 1.18;
          color: #d0021b;
        }
      }
    }  
  .buttonsContainer {
    // cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 -2px 12px 0 rgba(0,0,0,0.1);
    z-index: 10;
    >div {
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
      display: flex;
      padding-top: 20px;
      justify-content: flex-end;
      .button {
        width: 225px;
        margin: 0 10px;
      }
      @media screen and (max-width: 812px) {
        justify-content: center;
        .button {
          width: 40%;
          margin: 0 3%;
        }
      }
    }    
}