.rightButton {
    position: absolute;
    top: 15px;
    right: 40px;
}

.actionableButtons {
    display: flex;
    justify-content: end;

    position: absolute;
    top: 15px;
    right: 30px;

    min-width: 400px;

    .button:not(:last-child) {
        margin-right: .5rem;
    }
}
