.cardContainer.emotionalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #FFFFFF;
    .scrollContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 0 0;

        .diagramContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            
            .overlapContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
            }
        }

        .bottomContainer {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid #F0F3F7;
            
            .figureContainer {
                display: flex;
                box-sizing: border-box;
                width: calc( 100% - 5rem );
                justify-content: center;
                align-items: center;
                min-height: 3.54rem;
                padding: 0;
                margin: 0 auto;
                border-bottom: 1px solid rgba(223, 226, 234, 0.4);
                .figureTitle {
                    min-width: 7.5rem;
                    max-width: 7.5rem;
                    color: #000000;
                    line-height: 1.125rem;
                    text-align: right;
                    font-weight: 400;
                    font-family: 'azo-sans-web', sans-serif;
                    font-size: 0.85rem;
                }

                .barContainer {
                    width: 50%;
                    height: 0.625rem;
                    border-radius: 1px;
                    background-color: rgba(223, 226, 234, 0.3);
                    margin: 0 1.25rem;
                    position: relative;

                    .percentBar {
                        position: absolute;
                        border-radius: 1px;
                        width: 0;
                        background-color: #747A81;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        transition: all 1s linear;
                        -webkit-transition: all 1s linear;

                    }
                }

                .figureData {
                    width: 3.75rem;
                    color: #000000;
                    // font-family: 'azo-sans-web', sans-serif;
                    font-size: 0.9rem;
                    font-weight: 700;
                    line-height: 1.625rem;
                    text-align: right;
                    display: flex;
                    align-items: center;

                    .statArrow {
                        margin-left: 10px;
                    }
                }
                .upDownIcon {
                    display: flex;
                    align-items: flex-end;
                    >span {
                        right: 0;
                        top: 20px;
                    }
                }
                .icon {
                    width: 0.875rem;
                    height: 0.9rem;
                }
            }
        }
    }


}