.pageContainer .contentContainer .rightContainer .pageContent.advancedPage {
    .mainContent {
        background-color: rgb(245, 247, 250);
        .horizontalMenuBarContainer {
            height: auto;
            flex-wrap: wrap;
            .filterList {
                width: 100%;
                box-sizing: border-box;
                justify-content: flex-start;
                margin: 0;
                padding: 0 36px;
                border-bottom: 1px solid #DFE2EA;
            }
            &.prePost {
                @media only screen and (max-width: 1200px) {
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    border-bottom: none;
                }

                @media only screen and (max-width: 812px) {
                    height: 80px;
                    flex-direction: row;
                    border-bottom: 1px solid #DFE2EA;
                    &.periodClicked {
                        height: 200px;
                        border-bottom: none;
                    }

                }
                .middleHorizontalMenuBarContainer.prePost {
                    @media only screen and (max-width: 1200px) {
                        flex-wrap: wrap;
                        border-top: 1px solid #DFE2EA;
                        border-bottom: 1px solid #DFE2EA;
                        height: auto;
                        justify-content: flex-end;
                    }
                    .periodIconContainer {
                        display: none;
                    }
                    .periodPickerContainer {
                        &.fixedPeriod {
                            .inputBox.dateInput {
                                display: none;
                            }
                        }
                        &:last-child {
                            border-left: 1px solid #DFE2EA;
                            margin-left: 20px;
                            @media only screen and (max-width: 1200px) {
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }
        .brandsSelectContainer {
            margin-left: 20px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 700px;
            >.brandDropDown:nth-child(2) {
                border-left: 1px solid #DFE2EA;
            }
        }
        .advancedAnalyticsMainContainer{
            background-color: #F5F7FA;
        }
        .advancedAnalyticContainer {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            height: calc(100vh - 235px);
            min-height: 530px;
            position: relative;
            background-color: white;
            margin-left: 28px;
            margin-right: 28px;
            margin-bottom: 32px;
            &.prePost, &.landingPage {
                height: auto;
            }

            .mapContainer {
                width: calc(100% - 80px);
                height: 100%;
                margin: 0 auto;
                z-index: 0;
                &.noYaxis {
                    // width: 100%;
                }
            }
            .axisTitle {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                color: #000000;
                font-family: 'azo-sans-web', sans-serif;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 16px;
                text-transform: uppercase;
                &.yAxis {
                    width: 70px;
                    height: 100%;
                    border-right: 1px solid #DFE2EA;
                    position: relative;
                    >span {
                        transform: rotate(-90deg);
                        left: 0;
                        right: 0;
                        // position: absolute;
                    }
                }
                &.xAxis {
                    width: 100%;
                    height: 80px;
                    border-top: 1px solid #DFE2EA;

                    &.hidden {
                        display: none;
                    }
                }
            }
            .selectContainer {
                display: flex;
                padding: 20px 15px 0px 21px;
                justify-content: space-between;
                .inputBox {
                    width: 150px;
                    height: 45px;
                    box-sizing: border-box;
                    border-radius: 2.5rem;
                    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
                    color: #000000;
                    font-weight: 500;
                    line-height: 22px;
                    padding: 10px 30px;
                    margin-right: 20px;

                    img.ddArrow {
                        top: 16px !important;
                        right: 20px !important;
                        position: absolute;
                    }
                    .selectListContainer {
                        max-height: 247px;
                        top: 106%;
                    }
                }
                .withInformationText{
                    min-width: 300px;
                    color: #71767d;
                    display: flex;
                    align-items: center;
                    >span {
                            display: block;
                            position: relative;
                            border: none;
                            box-shadow: none;
                            width: 100%;
                            text-align: right;
                            z-index: 0;
                            font-size: 13px;
                    }
                }
            }
        }
    }
}
